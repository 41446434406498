.main-title {
  width: 100%;
  background-color: #fff;
  height: 150px;
  text-align: center;
  display: block;
  color: #505050;
  font: {
    family: $ossem;
    size: rem(32px);
    weight: 600;
  }
  line-height: rem(150px);
  position: relative;

  @media (max-width: 1410px) {
    min-height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    height: auto;
    font-size: rem(32px);
    line-height: rem(34px);

  }

  &.catalog {
    background: transparent;
    text-align: left;
    height: auto;
    line-height: 40px;
    margin-top: rem(18px);

    @media (max-width: 1140px) {
      height: auto;
      padding: 8px 0 0;
    }
    @media(max-width: 768px) {
      margin: 8px 0 0;
      padding: 0;
    }

    .breadcrumbs {
      display: none;
    }

    .main-title-img {
      display: none;
    }

    h1 {
      background: transparent;
      text-align: center;
      font-size: rem(24);
      line-height: 150%;
      color: #404040;

      @media(max-width: 550px) {
        line-height: 120%;
      }
    }
  }

  h1 {
    position: relative;
    z-index: 2;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.3) 100%);
  }

  @media(max-width: 560px) {
    font-size: rem(26px);
  }

  &-img {
    position: absolute;
    display: none;
    width: 50%;
    top: 0;
    height: 150px;
    overflow: hidden;
    @media (max-width: 1410px) {
      display: none;
    }

    &.left {
      left: 0;
    }

    &.right {
      right: 0;
    }

    img {
      object-fit: cover;
      width: 100%;
    }
  }
  &-text {
    display: block;
    margin: 16px auto 0;
    max-width: 630px;
    font-size: 12px;
    line-height: 150%;
    color: #676463;
    text-align: center;
    font-weight: $font-weight--regular;
    @media(max-width: 768px) {
      margin: 8px 0 0;
    }
  }

  .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1.3;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap;
    @media (max-width: 1410px) {
      position: static;
      transform: none;
      padding-top: 10px;
      //margin-bottom: 10px;
    }
    //@media (max-width: 1140px){
    //
    //}
    li {
      line-height: 1.3;
      margin-right: 30px;
      position: relative;
      margin-bottom: 10px;

      &:after {
        position: absolute;
        content: '';
        right: -19px;
        width: 10px;
        top: 50%;
        height: 2px;
        background-color: #edae00;
        display: block;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      a {
        display: block;
        font: {
          size: 14px;
          family: $baseFonts;
          weight: $font-weight--regular;
        };
        color: #727272;
        line-height: 1.3;
        text-align: start;
      }
    }
  }
}
.constructor{
  max-width: 1920px;
  margin-top: -40px;
  .slider{
    position: relative;
    background:url('../images/bg-slider.jpg') no-repeat top center;
    background-size:cover ;
    width: 100%;
    max-height:760px;
  }
  .header-block{
    position: absolute;
    z-index: 1000;
    margin-top: 20px;
    &:after{
      bottom: -15px;
    }
  }
  .header-discription{
    width: 100%;
     z-index: 1000;
    position: absolute;
    text-align: center;
    margin-top: 90px;
    font:{
      size: rem(14px);
      weight: $font-weight--regular;
      family: $baseFonts;
    }
    color: #8b8b8b;
  }

  .bigger-slider{
    width: 100%;
    height: 90%;
    position: relative;
    z-index: 999;

    .swiper-wrapper{

      .swiper-slide{
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img{
          max-width: 100%;
          margin: auto;
          height: auto;
        }

        .mark{
          position: absolute;
          display: flex;
          align-items:flex-start;
          width: auto;
          cursor: pointer;
          .plus{
            @include sprite($mark-plus);
            margin-right: 15px;
          }

          .name{
            font:{
              size: rem(14px);
              weight: $font-weight--bold;
            }
            line-height: 21px;
            color: #000;
            text-transform: uppercase;
          }
          .price{
            line-height: rem(21px);
            font-weight: $font-weight--lite;
            &-old{
              font-size: rem(14px);
              color: #8b8b8b;
              text-decoration:line-through;
            }
            &-normal{
              font-size: rem(21px);
              color: #477ab3;
            }
          }
        }
        .swiper-slide-shadow-left{
              background-image: linear-gradient(to left,rgba(255, 255, 255, 0.5),rgba(0,0,0,0));
        }
        .swiper-slide-shadow-right{
          background-image: linear-gradient(to right,rgba(255, 255, 255, 0.5),rgba(0,0,0,0))
        }
      }
    }
  }
  
  .calculator{
    background-color: #e8edec;
    padding:55px 0 35px 140px ;
    position: relative;z-index: 10000;

    &-inner{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-item{
      max-width: 400px;
      width: 100%;
      height: 165px;
       position: relative;
      &:not(.finish){
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .close{
          position:absolute;
          right: 10px;
          top: 10px;
          @include sprite($close)
        }
        .img{
          width: 154px;
          height: 154px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
            object-fit: cover;
          }
        }
        .info{
          padding:0 20px 0 20px;
          box-sizing: border-box;
          .name{
            font:{
              size: rem(14px);
              weight: $font-weight--bold;
              family: $baseFonts;
            }
            line-height: rem(21px);
            margin-bottom: 10px;
          }
          .price{
            line-height: rem(21px);
            font-weight: $font-weight--lite;
            &-old{
              font-size: rem(14px);
              color: #8b8b8b;
              text-decoration:line-through;
            }
            &-normal{
              font-size: rem(21px);
              color: #477ab3;
            }
          }
        }
      }
      &.finish{
        padding: 20px;
        box-sizing: border-box;
        width: auto;
        max-width:400px ;
        min-width: 300px;
        .info{
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          & > div{
            width: 50%;
          }
          .price{
            line-height: rem(32px);
            font-weight: $font-weight--lite;
            &-old{
              font-size: rem(18px);
              color: #8b8b8b;
              text-decoration:line-through;
            }
            &-normal{
              font-size: rem(28px);
              color: #477ab3;
            }
          }
          .sale{
            width: auto;
            height: 50px;
            color: #ffcfae;
            font-size: rem(12px);
            line-height: rem(21px);
            text-transform: uppercase;
            background-color:#fa7a24;
            padding: 5px 20px 5px 10px;
            border-radius: 5px;
            text-align: center;
            position: relative;
            span{
              display: block;
              color: #fff;
              font-size:rem(24px) ;
              font-weight: $font-weight--bold;
            }
            &:before{
              position: absolute;
              content: '';
              border: 25px solid transparent;
              border-right: 27px solid #fa7a24;
              width: 50px;
              height: 50px;
              box-sizing: border-box;
              left: -49px;
              top: 0;
            }
          }
        }
        .btn {
            display: block;
            width: auto;
            min-width: 100px;
            height: 50px;
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 8px;
            background-color: $green;
            text-align: center;
            transition: .2s;
            span {
              line-height: 50px;
              color: #ffffff;
              font-family: $baseFonts;
              font-size: rem(14px);
              font-weight: $font-weight--bold;
              text-transform: uppercase;

            }
            &:hover {
              text-decoration: none;
              background-color: lighten($green, 10%);
              span {
                text-decoration: none;
              }
            }
          }
      }
    }
    .plus,.summ{
      font-size: 40px;
      font-weight: $font-weight--lite;
      text-align: center;
      width:40px;
      height: 40px;
      color: #747676;
    }
  }

}
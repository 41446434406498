.showcase {
  margin-top: 40px;
  background-color: #f8f8f8;
  width: 100%;
  position: relative;
  @media (max-width: 1095px) {
    padding-top: 40px;
  }
  @media (max-width: 500px) {
    padding-top: 10px;
  }

  .title {
    text-align: center;
    color: #292929;
    font: {
      family: $baseFonts;
      size: rem(21px);
      weight: $font-weight--bold;
    }
    line-height: 21px;
    margin-bottom: 60px;
    @media (max-width: 800px) {
      margin-bottom: 15px;
    }
  }

  .header-block {
    margin-bottom: 32px;

    &:after {
      display: none;
    }

    @media (max-width: 891px) {
      &:after {
        display: none;
      }
    }
  }

  &-btn {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    @media (max-width: 891px) {
      display: none;
    }

    .rainbow {
      position: absolute;
      width: auto;
      height: 40px;
      top: 32px;
      right: 0;
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      box-sizing: border-box;
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
      background-color: rgba(255, 255, 255, 0.9);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 8px;
      @include transition(.5s);

      .select-color {
        display: block;
        width: 20px;
        height: 20px;
        margin: 10px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        &.active {
          &:after {
            border-color: #26937a;
          }
        }

        .color-name {
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          background-color: #fff;
          padding: 2px 15px;
          box-sizing: border-box;
          width: auto;
          display: none;
          color: #26dabf;
          border: 2px solid #f4f0f0;
          border-radius: 10px;
          word-wrap: normal;
          word-break: normal;
          white-space: nowrap;
        }

        &-text {
          display: block;
          width: 100%;
          position: absolute;
          z-index: -50;
          opacity: 0;
          text-indent: -99999px;
          overflow: hidden;
        }

        &::after {
          position: absolute;
          content: '';
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          border: 2px solid #f4f0f0;
          border-radius: 50%;
          transition: all 0.2s ease-in-out 0s;
        }

        &:hover {
          &::after {
            border-color: lighten($green, 40%);
          }

          .color-name {
            display: block;
          }
        }
      }

      &.active {
        width: auto;
        height: 40px;
        right: 100%;
        opacity: 1;
        pointer-events: all;

        .select-color {
          margin: 10px;
        }
      }
    }

    .color-btn {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      font: {
        family: $baseFonts;
        size: rem(14px);
        weight: $font-weight--bold;
      }
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      color: #1C4A9F;
      @include transition();

      .icon {
        margin-right: 20px;
        display: none;
      }

      &:hover {
        text-decoration: none;
        //color: #17bfa6;
        opacity: 0.8;
      }
    }
  }

  &-list {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 30px;
    flex-wrap: wrap;
    position: relative;
    @media (max-width: 630px) {
      display: block;
      margin-bottom: 20px;
    }

    &.five-item {
      .swiper-container {
        @media(min-width: 631px) {
          overflow: visible;
          height: auto !important;
          width: 100% !important;
        }
      }

      .swiper-wrapper {
        @media(min-width: 631px) {
          transform: none;
          width: auto;
          height: auto;
          flex-wrap: wrap;
        }
      }

      .swiper-btn {
        display: none;
        @media(max-width: 630px) {
          display: block;
          position: absolute;
          z-index: 2;
          overflow: hidden;
          text-indent: -99999px;
          border: none;
          background-color: rgba(255, 255, 255, .6);
          z-index: 99;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          &--prev {
            background-image: url(../images/slider-arrow-prev.png);
            width: 50px;
            height: 50px;
            background-size: contain;
            left: 0;
          }
          &--next {
            background-image: url(../images/slider-arrow-next.png);
            width: 50px;
            height: 50px;
            background-size: contain;
            right: 0;
          }
        }
      }

      .showcase-item {
        width: calc(100% / 5 - 16px);

        &:nth-child(4n+4) {
          margin-right: 20px;
        }

        &:nth-child(5n+5) {
          margin-right: 0;
        }

        @media (max-width: 1540px) {
          width: calc(100% / 4 - 16px);
          &:nth-child(4n) {
            margin-right: 0;
          }
          &:nth-child(5n) {
            margin-right: 20px;
          }
        }
        @media (max-width: 1240px) {
          width: calc(100% / 3 - 16px);
          &:nth-child(4n) {
            margin-right: 20px;
          }
        }
        @media (max-width: 960px) {
          width: calc(100% / 2 - 16px);
        }
        @media (max-width: 630px) {
          width: 100%;
        }
      }
    }

    .show-more-card {
      padding-top: 10px;
      padding-left: 5px;
      font: {
        size: rem(18px);
        weight: $font-weight--semibold;
      }
      color: $link-color;
      @include transition();
      border: none;
      background-color: transparent;
      margin: 0 auto 16px auto;
      display: none;

      &.active {
        display: block;
        @media (max-width: 630px) {
          display: none !important;
        }

      }

      &:hover {
        text-decoration: none;
        color: $link-color--hover;
      }

    }

    .show-smaller-card {
      padding-top: 10px;
      padding-left: 5px;
      font: {
        size: rem(18px);
        weight: $font-weight--semibold;
      }
      color: lighten($green, 10%);
      @include transition();
      border: none;
      background-color: transparent;
      margin: 0 auto 16px auto;
      display: none;

      &.active {
        display: block;
      }

      &:hover {
        text-decoration: none;
        color: $yellow;
      }

      @media (max-width: 630px) {
        background-image: url(../images/slider-arrow-next.png);
        width: 50px;
        height: 50px;
        background-size: contain;
        right: 0;
        z-index: 999;
        display: none;
        position: absolute;
        overflow: hidden;
        border: none;
        background-color: rgba(255, 255, 255, 0.6);
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 0;
      }
    }
  }

  &-item {
    width: calc(100% / 4 - 16px);
    //max-height: 428px;
    height: auto;
    margin: 0 20px 20px 0;
    padding: 20px 0;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;

    @include transition();
    @media (max-width: 1240px) {
      width: calc(100% / 3 - 16px);
      &:nth-child(3n + 3 ) {
        margin-right: 0 !important;
      }
    }
    @media (max-width: 960px) {
      width: calc(50% - 10px);
      &:nth-child(3n + 3 ) {
        margin-right: 20px !important;
      }
      &:nth-child(2n + 2 ) {
        margin-right: 0 !important;
      }
    }
    @media (max-width: 630px) {
      width: 90%;
      max-width: 420px;
      margin-right: auto !important;
      margin-left: auto;
      padding: 15px 0;
      &:nth-child(3n + 3 ) {
        margin-right: auto !important;
      }
      &:nth-child(2n + 2 ) {
        margin-right: auto !important;
      }
    }

    &:nth-child(4n + 4) {
      margin-right: 0;
      @media (max-width: 1240px) {
        margin-right: 20px;
      }
    }

    &:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
      max-height: none;
      z-index: 15;
      border-radius: 8px 8px 0 0;

      .img img {
        transform: scale(1.1);
      }

      .showcase-item-inner {
        //position: absolute;
        position: relative;
        box-shadow: 0 8px 8px rgba(56, 56, 56, 0.2);
        //left: 0;
        //right: 0;
        //padding: 0 20px 20px;
        @media (max-width: 1240px) {
          position: relative;
          box-shadow: none;
          left: auto;
          right: auto;
          //padding: 0 20px 20px;
        }

        .showcase-item-hide {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          height: auto;
          padding: 20px;
          box-shadow: 0 8px 8px rgba(56, 56, 56, 0.2);
          border-radius: 0 0 8px 8px;
          @media (max-width: 1240px) {
            display: none;
            padding: 0;
          }
        }
      }
    }

    .title {
      color: #292929;
      padding: 0 20px;
      box-sizing: border-box;
      font: {
        family: $baseFonts;
        size: rem(14px);
        weight: $font-weight--bold;
      }
      line-height: rem(26px);
      //height: 42px;
      //display: block;
      flex-grow: 1;
      margin-bottom: 0;
      text-align: left;

      display: flex;
      align-items: center;

      &:hover {
        text-decoration: none;
        color: lighten(#292929, 10%);
      }

      //& > * {
      //  text-overflow: ellipsis;
      //  white-space: nowrap;
      //  overflow: hidden;
      //}
    }

    .flag-list {
      position: absolute;
      top: 105px;
      left: 40px;
      display: flex;
      z-index: 2;
      flex-direction: column;
    }

    .flag {
      position: relative;
      width: auto;
      margin-bottom: 10px;
      color: #ffffff;
      font: {
        family: $baseFonts;
        size: rem(16px);
        weight: $font-weight--bold;
      }
      line-height: 1.3;
      letter-spacing: 0.008em;
      padding: 0 33px 0 20px;
      @media (max-width: 1140px) {
        height: 30px;
        line-height: 30px;
        border-radius: 8px;
        &:after {
          display: none;
        }
      }
      @media (max-width: 560px) {
        top: 80px;
        left: 20px;
        right: auto;
      }

      &.hit {
        &:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #FFCD1D;
          border-radius: 50%;
        }
      }

      &.new {
        &:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #1C4A9F;
          border-radius: 50%;
        }
      }

      &.sale {

        &:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #FEC1CC;
          border-radius: 50%;
        }
      }
    }

    .img {
      max-width: 100%;
      position: relative;
      padding-bottom: percentage(4/5);
      height: auto;
      width: 100%;
      overflow: hidden;
      margin: 24px auto 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;
      @media (max-width: 1400px) {
        margin: 20px auto;
      }
      @media (max-width: 630px) {
        margin: 15px auto;
      }

      &:hover {
        .fast-view {
          opacity: 1;
          display: none;
          @media (max-width: 1240px) {
            display: none;
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
        @include transition(.4s);

      }

      .fast-view {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .8);
        display: flex;
        align-items: center;
        justify-content: center;
        @include transition();
        opacity: 0;
        display: none;

        &-btn {
          width: 220px;
          height: 50px;
          border: 2px solid #555555;
          text-align: center;
          @include transition();

          span {
            color: #292929;
            font: {
              family: $baseFonts;
              weight: $font-weight--bold;
              size: rem(14px);
            }
            line-height: rem(50px);
            text-transform: uppercase;
          }

          &:hover {
            text-decoration: none;
            box-shadow: 0 0 3px rgba(0, 0, 0, .5);
          }
        }
      }
    }

    &-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 39px;
      background-color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      margin: auto 0 0;
    }

    .info {
      display: none;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      @media(max-width: 1024px) {
        display: flex;
      }

      p {
        width: 100%;
      }

      .options {
        color: #a2a2a2;
        font: {
          family: $baseFonts;
          size: rem(14px);
          weight: $font-weight--regular;
        }
        line-height: 30px;
        display: inline-block;
        margin-right: 5px;
      }

      .value {
        color: #333a40;
        font: {
          family: $baseFonts;
          size: rem(14px);
          weight: $font-weight--regular;
        }
        line-height: 30px;
      }

      &-color {
        width: 100%;

        .value {
          display: block;
          margin-top: 15px;
          margin-bottom: 0;
          padding-left: 10px;

          .color-radio {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 9px;
            //&:first-child{
            //  margin-left: 10px;
            //}

            &-value {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              border-radius: 50%;
              display: inline-block;
              cursor: pointer;
              overflow: hidden;
              text-indent: -9999px;

              &:after {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                border: 2px solid #f4f0f0;
                border-radius: 50%;
                top: -7px;
                left: -7px;
                @include transition();
              }

              &:hover {
                &::after {
                  border-color: lighten($green, 40%);
                }
              }
            }

            input {
              position: absolute;
              top: 8px;
              left: 8px;
              z-index: -1;

              &:checked + span.color-radio-value:after {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    &-hide {
      width: 100%;
      height: 0;
      overflow: hidden;
      background-color: #fff;

      .info {
        display: flex;
        
        @media(max-width: 1024px) {
          display: none;
        }
      }

    }

    &-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .price {
        text-align: left;
        //text-transform: uppercase;
        font: {
          family: $baseFonts;
          size: rem(16px);
          weight: $font-weight--lite;
        };
        line-height: rem(21px);

        &-old {
          color: #8b8b8b;
          text-decoration: line-through;
          height: 18px;
        }

        &-normal {
          color: #404040;
          font-size: rem(21px);
        }
      }

      .basket {
        a {
          color: #1C4A9F;
          font: {
            family: $baseFonts;
            size: rem(14px);
            weight: $font-weight--bold;
          }
          line-height: 21px;
          text-transform: uppercase;
          display: flex;
          align-items: center;

          .icon {
            margin-right: 11px;
          }

          &:hover {
            text-decoration: none;
            color: lighten($green, 10%);
          }
        }
      }
    }

  }


}
.main {
  width: 100%;
  height: auto;

  .main-slider {
    height: calc(100vh - 50px);
    min-height: 500px;
    margin-bottom: 24px;
    @media (max-width: 1095px) {
      height: calc(100vh - 122px);
      padding: 0;
    }
    @media (max-width: 768px) {
      min-height: 450px;
      height: calc(100vh - 132px);
    }
    @media (max-width: 425px) {
      min-height: 400px;
      height: calc(100vh - 112px);
    }

    .swiper-container {
      height: 100%;
    }

    .swiper-slide {
      position: relative;


      picture {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;

        img {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover;
        }

      }

      &__bottom {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1150px;
        max-width: 90%;
        @media (max-width: 1095px) {
          max-width: 80%;
        }

        @media (max-width: 425px) {
          max-width: calc(100% - 32px);
        }
      }

      &__title {
        font-size: rem(48);
        line-height: rem(64);
        font-weight: 600;
        color: $white;
        max-width: 70%;
        margin-bottom: 64px;
        @media (max-width: 1095px) {
          font-size: rem(40);
          line-height: 1.3;
        }
        @media (max-width: 768px) {
          font-size: rem(30);
          margin-bottom: 40px;
        }
        @media (max-width: 425px) {
          font-size: rem(28);
          max-width: 100%;
        }
      }

      &__link {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 12px 24px;
        font-weight: 600;
        cursor: pointer;
        color: #39B79C;

        &:hover {
          text-decoration: none;
        }
      }


    }

    .pagination-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      z-index: 10;

      .swiper-pagination-fraction {
        min-width: 130px;
        position: static;
        color: $white;
        padding: 32px;
        width: max-content;
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        font-size: rem(20);
        line-height: rem(24);
        font-weight: 600;
        @media (max-width: 768px) {
          font-size: rem(17);
        }
        @media (max-width: 425px) {
          font-size: rem(15);
          padding: 24px 32px;
        }

        .swiper-pagination-current {

        }
      }
    }

    .swiper-button {
      width: 36px;
      height: 36px;
      border: none;
      position: absolute;
      z-index: 2;
      top: 50%;
      background-image: url('../images/slider-arrow.png');
      -webkit-background-size: contain;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: rgba(255, 255, 255, 1);
      border-radius: 50%;
      @include transition();

      &:hover {
        background-image: url('../images/slider-arrow-hover.png');
      }

      @media (max-width: 768px) {
        display: none;
      }

      &-prev {
        left: 16px;
        transform: translateY(-50%);
      }

      &-next {
        right: 16px;
        transform: translateY(-50%) rotate(180deg) !important;
      }
    }
  }

  &-banner {
    width: 100%;
    margin-bottom: 80px;
    @media (max-width: 1095px) {
      margin-bottom: 60px;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media (max-width: 560px) {
      margin-bottom: 12px;
    }

    &--wrapper {
      padding: 0 15px;
      max-width: 1920px;
      @media (max-width: 560px) {
        padding: 0;
      }
    }

    .swiper-slide {

      .bg {
        display: none;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        @media (max-width: 560px) {
          display: block;
        }
        //background: rgba(64, 64, 64, 0.4);
      }

      a {
        display: block;
        width: 100%;
      }
    }

    .main-banner__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 2;
      left: 11.1%;
      bottom: 13.92%;
      @media (max-width: 560px) {
        display: none;
      }

      .main-banner__btn {
        position: relative;
        @include transition();
        width: 26px;
        min-width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, .4);

        &:hover {
          border: 1px solid rgba(255, 255, 255, 1);
        }

        &--prev {
          margin-right: 12px;

          &::after {
            content: '';
            width: 6px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url(../images/vector/main-banner-arrow.svg);
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(-50%, -50%);
          }
        }

        &--next {
          margin-left: 12px;

          &::after {
            content: '';
            width: 6px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url(../images/vector/main-banner-arrow.svg);
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }

      .main-banner__pagination {

        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: $white;
          opacity: .5;
          margin: 0 3px;

          &-active {
            opacity: 1;
          }
        }
      }
    }

    .off-controls & ~ .main-banner__controls {
      display: none;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      font-family: 'object-fit:cover;';
      min-height: 500px;
      max-height: calc(100vh - 50px);

      @media (max-width: 560px) {
        height: auto;
        max-height: unset;
      }
    }

    &-text {
      position: absolute;
      z-index: 3;
      top: 40.55%;
      left: 11.1%;
      display: flex;
      flex-direction: column;
      max-width: 401px;
      @media (max-width: 1200px) {
        max-width: 280px;
      }
      @media (max-width: 1024px) {
        max-width: 230px;
        top: 13.92%;
      }
      @media (max-width: 480px) {
        left: 5%;
        max-width: 90%;
      }

      .title {
        font-weight: $font-weight--bold;
        font-size: 32px;
        line-height: 140%;
        color: #ffffff;
        word-break: break-word;
        @media (max-width: 1200px) {
          font-size: 22px;
        }
        @media (max-width: 768px) {
          font-size: 18px;
        }
        @media (max-width: 560px) {
          font-weight: 600;
          font-size: 14px;
          text-align: left;
        }
        @media(max-width: 480px) {
          display: none;
        }

        &-mobile {
          display: none;
          @media(max-width: 480px) {
            display: block;
            color: #ffffff;
            font-weight: 600;
            font-size: 14px;
            text-align: left;
            word-break: break-all;
          }
        }
      }

      .link {
        position: relative;
        align-self: flex-start;
        margin-top: 16px;
        font-weight: $font-weight--bold;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #ffffff;
        @media (max-width: 768px) {
          margin-top: 10px;
        }
        @media (max-width: 560px) {
          display: none;

        }

        &:after {
          content: '';
          position: absolute;
          top: calc(100% + 8px);
          left: 0;
          display: block;
          width: 100%;
          height: 2px;
          background-color: #ffffff;
          @media (max-width: 560px) {
            top: calc(100% + 3px);
          }
        }
      }
    }
  }

  &-video {
    width: 100%;
    margin-bottom: 80px;
    @media (max-width: 1095px) {
      margin-bottom: 60px;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media (max-width: 560px) {
      margin-bottom: 12px;
    }

    &__video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 500px;
      max-height: calc(100vh - 50px);
      @media (max-width: 560px) {
        height: calc(100vh - 112px);
      }
    }
  }

  &-categories {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 -16px 80px;
    @media (max-width: 1095px) {
      margin-bottom: 60px;
    }
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
    @media (max-width: 560px) {
      margin-bottom: 12px;
    }

    &__item {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      margin: 0 6px 12px;
      display: block;
      overflow: hidden;
      background-color: #fff;

      @media (min-width: 1120px) {
        &:nth-child(3n + 1), &:nth-child(3n), &:nth-child(4n), &:nth-child(5n) {
          position: relative;
          display: flex;
          max-width: calc(40% - 32px);

          .inner {
            padding-bottom: 76%;
            position: relative;
            width: 100%;
          }
        }
        &:nth-child(6n - 4), &:nth-child(6n) {
          position: relative;
          display: flex;
          max-width: calc(20% + 28px);

          .inner {
            padding-bottom: 64%;
            position: relative;
            width: 100%;
          }
        }
      }
      @media (max-width: 1119px) {
        position: relative;
        display: flex;
        max-width: calc(40% - 12px);

        .inner {
          padding-bottom: 64%;
          position: relative;
          width: 100%;
        }


        &:nth-child(4n + 1), &:nth-child(4n) {
          position: relative;
          display: flex;

          max-width: calc(60% - 12px);

          .inner {
            padding-bottom: 76%;
            position: relative;
            width: 100%;
          }
        }

        //&:nth-child(4n){
        //  position: relative;
        //  display: flex;
        //  max-width: calc(70% - 20px);
        //
        //  .inner {
        //    padding-bottom: 76%;
        //    position: relative;
        //    width: 100%;
        //  }
        //}
        //&:nth-child(5n), &:nth-child(6n) {
        //  position: relative;
        //  display: flex;
        //  max-width: 100%;
        //
        //  .inner {
        //    padding-bottom: 50%;
        //    position: relative;
        //    width: 100%;
        //  }
        //}
      }


      &:hover {
        box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);

        .title {
          opacity: 0;
          @media (max-width: 1200px) {
            opacity: 1;
          }
        }

        .hover-block {
          background-color: rgba(0, 0, 0, .4);

          .hover-block__discription {
            opacity: 1;
            top: calc(50% + 30px);
            @media (max-width: 1124px) {
              display: none;
            }
          }
        }
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%;

      }

      .hover-block {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        @include transition(.2s);

        &__content {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          padding: 0 20px;
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          @media (max-width: 1119px) {
            padding: 0 5px;
          }

        }

        &__title {
          position: relative;
          width: auto;

          display: block;
          color: #fff;
          font: {
            family: $baseFonts;
            size: rem(24px);
            weight: $font-weight--bold
          }
          line-height: 1.3;
          text-align: center;
          margin: auto;

          @media (max-width: 1119px) {
            font-size: rem(14px);
          }
        }

        &__discription {
          position: absolute;
          bottom: -100%;
          display: block;
          color: #fff;
          font: {
            family: $baseFonts;
            size: rem(14px);
            weight: $font-weight--regular;
          }
          line-height: 21px;
          max-width: 380px;
          text-align: center;
          margin: auto auto 20px;
          opacity: 0;
          @media (max-width: 1124px) {
            display: none;
          }
        }
      }
    }
  }
}

.popular {
  width: 100%;
  max-width: 1920px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 0 20px;
  @media(max-width: 1140px) {
    flex-wrap: wrap;
    margin-bottom: 60px;
  }
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }

  &-item {
    margin-right: 20px;
    flex-basis: 50%;
    width: calc(50% - 10px);
    max-width: 950px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media(max-width: 1140px) {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }

    &:last-child {
      margin-right: 0;
    }

    &__set {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-bottom: percentage(4/5);
      @media (max-width: 1140px) {
        //padding-bottom: percentage(1/2);
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .2);
      }

      .title {
        position: absolute;
        z-index: 9;
        top: 32px;
        left: 24px;
        width: auto;
        height: auto;
        color: #fff;
        font: {
          family: $baseFonts;
          size: rem(24px);
          weight: $font-weight--bold
        }
        line-height: 1.3;
        text-align: left;
        max-width: calc(100% - 24px - 200px - 16px);
        @media (max-width: 1140px) {
          width: auto;
          line-height: rem(40px);
        }
        @media (max-width: 560px) {
          font-size: 14px;
          top: 10px;
          max-width: calc(100% - 48px);
        }
      }

      .flag {
        width: auto;
        position: absolute;
        z-index: 10;
        right: 30px;
        top: 40px;
        color: #ffffff;
        font: {
          family: $baseFonts;
          size: rem(16px);
          weight: $font-weight--bold;
        }
        line-height: 1.3;
        letter-spacing: 0.008em;
        padding: 0 33px 0 20px;
        @media (max-width: 1140px) {
          height: 30px;
          line-height: 30px;
          border-radius: 8px;
          top: 36px;
        }
        @media (max-width: 560px) {
          top: auto;
          bottom: 10px;
          left: 20px;
          right: auto;
          &:after {
            width: 10px !important;
            height: 10px !important;
            left: 0 !important;
          }
        }

        &.hit {
          &:after {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
            background-color: #FFCD1D;
            border-radius: 50%;
          }
        }

        &.new {
          &:after {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
            background-color: #1C4A9F;
            border-radius: 50%;
          }
        }

        &.sale {

          &:after {
            position: absolute;
            content: '';
            width: 22px;
            height: 22px;
            box-sizing: border-box;
            top: 50%;
            transform: translateY(-50%);
            left: -20px;
            background-color: #FEC1CC;
            border-radius: 50%;
          }
        }
      }

      img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%;
        @media (max-width: 1140px) {
          min-width: 100%;
          min-height: 100%;

        }
      }
    }

    &__slider {
      width: 100%;
      height: 100%;

      .popular-slider {
        height: 100%;

        .swiper-button-next, .swiper-button-prev {
          outline: none;
        }

        .swiper-wrapper {

          .swiper-slide {
            width: 100%;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (max-width: 1140px) {
              min-height: 250px;

            }

            .title {
              position: absolute;
              left: 20px;
              top: 35px;
              max-width: none;
              width: auto;
              box-sizing: border-box;
              color: #fff;
              font: {
                family: $baseFonts;
                size: rem(24px);
                weight: $font-weight--bold
              }
              line-height: 1.3;
              @media (max-width: 1140px) {
                width: auto;
                padding: 0 20px 0 10px;
              }
            }

            img {
              height: 100%;
              display: block;
              object-fit: cover;
              width: 100%;
              @media (max-width: 1140px) {
                min-width: 100%;
                min-height: 100%;

              }
            }

            .flag {
              width: auto;
              position: absolute;
              right: 30px;
              top: 40px;
              color: #ffffff;
              font: {
                family: $baseFonts;
                size: rem(16px);
                weight: $font-weight--bold;
              }
              line-height: 1.3;
              letter-spacing: 0.008em;
              padding: 0 33px 0 20px;
              @media (max-width: 1140px) {
                height: 30px;
                line-height: 30px;
                border-radius: 8px;
                &:after {
                  display: none;
                }
              }
              @media (max-width: 560px) {
                top: 80px;
                left: 20px;
                right: auto;
              }

              &.hit {
                &:after {
                  position: absolute;
                  content: '';
                  width: 22px;
                  height: 22px;
                  box-sizing: border-box;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -20px;
                  background-color: #FFCD1D;
                  border-radius: 50%;
                }
              }

              &.new {
                &:after {
                  position: absolute;
                  content: '';
                  width: 22px;
                  height: 22px;
                  box-sizing: border-box;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -20px;
                  background-color: #1C4A9F;
                  border-radius: 50%;
                }
              }

              &.sale {

                &:after {
                  position: absolute;
                  content: '';
                  width: 22px;
                  height: 22px;
                  box-sizing: border-box;
                  top: 50%;
                  transform: translateY(-50%);
                  left: -20px;
                  background-color: #FEC1CC;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
    }
  }

}

.main-showcase {
  margin-bottom: 60px;
  @media (max-width: 1095px) {
    margin-top: 20px;
  }
  @media (max-width: 1095px) {
    margin-bottom: 40px;
  }
  @media (max-width: 768px) {
    margin-bottom: 0;
  }

  .showcase-list {
    @media(max-width: 630px) {
      position: relative;
    }

    &__btn {
      width: 50px;
      height: 50px;
      border: none;
      position: absolute;
      z-index: 2;
      top: 50%;
      background-image: url(../images/slider-arrow-prev.png);
      -webkit-background-size: contain;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: rgba(255, 255, 255, .6);
      @media(min-width: 631px) {
        display: none !important;
      }

      &--prev {
        left: 0;
        transform: translateY(-50%);
      }

      &--next {
        right: 0;
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .swiper-container {
      @media(min-width: 631px) {
        overflow: visible;
      }
      @media(max-width: 630px) {
        width: calc(100% - 116px);
        max-width: 420px;
      }
      @media(max-width: 425px) {
        width: 100%;
      }
    }

    .swiper-wrapper {
      @media(min-width: 631px) {
        flex-wrap: wrap;
      }
    }
  }

  .showcase-list.showcase-slider-desktop {

    .swiper-container {
      @media(min-width: 631px) {
        overflow: hidden;
      }
    }

    .swiper-wrapper {
      @media(min-width: 631px) {
        flex-wrap: nowrap;
      }
    }

    .swiper-slide {
      @media(max-width: 960px) {
        margin-right: 20px !important;
      }
      @media(max-width: 630px) {
        margin-right: 20px !important;
      }

      &:hover {
        border-radius: 8px;

        .showcase-item-hide {
          display: none;
          padding: 0;
        }

        .showcase-item-inner {
          box-shadow: none;
        }
      }
    }

    .showcase-list__btn {
      @media(min-width: 631px) {
        display: block !important;
      }
    }
  }
}

.info-block {
  width: 100%;
  display: flex;
  //align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  padding: 0 20px;
  @media (max-width: 825px) {
    flex-direction: column-reverse;
    height: auto;
    margin-bottom: 33px;
    max-height: unset;
  }

  .img {
    width: 50%;
    display: block;
    position: relative;
    @media (max-width: 1124px) {
      height: auto;
    }
    @media (max-width: 1090px) {
      width: 35%;
    }
    @media (max-width: 825px) {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      //position: absolute;
      //top: 50%;
      //left: 50%;
      //transform: translate(-50%,-50%);
    }
  }

  .info-content {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: auto;
    background: {
      //image: url("../images/info-block-bg.jpg");
      position: center;
      repeat: no-repeat;
      size: cover;
    }
    padding: 50px 133px;
    text-align: center;
    color: $base-color;
    font: {
      family: $baseFonts;
      size: rem(14px);
      weight: $font-weight--regular;
    }
    @media(max-width: 1366px) {
      padding: 50px 24px;
    }
    @media (max-width: 1090px) {
      padding: 25px 4%;
      width: 65%;
    }
    @media (max-width: 825px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      padding-top: 0;
    }
    @media (max-width: 400px) {
      //text-align: left;
      padding: 25px 10px;
    }

    .header-block {
      margin-bottom: 32px;
      @media (max-width: 1090px) {
        font-size: rem(25px);
        line-height: normal;
        &:after {
          bottom: -5px;
        }
      }
      @media (max-width: 768px) {
        margin-bottom: 16px;
      }
    }

    &-btn {
      a {
        width: 260px;
        height: 50px;
        border-radius: 8px;
        background-color: $green;
        @include transition();
        display: block;
        line-height: 50px;
        color: #ffffff;
        font: {
          family: $baseFonts;
          size: rem(14px);
          weight: $font-weight--bold;
        }
        text-transform: uppercase;

        &:hover {
          background-color: lighten($green, 10%);
          text-decoration: none;
        }
      }
    }

  }
}

.about {
  width: 100%;
  //height: 524px;
  overflow: hidden;
  margin-bottom: 80px;
  @media (max-width: 1124px) {
    height: auto;
  }
  @media (max-width: 1095px) {
    margin-bottom: 60px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  @media (max-width: 560px) {
    margin-bottom: 12px;
  }

  &-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff;
    @media (max-width: 1550px) {
      flex-wrap: wrap;
    }
    @media(max-width: 825px) {
      display: block;
    }

    .about-content {
      width: 50%;
      padding: 60px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: 110px -50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      //text-align: center;
      @media (max-width: 1550px) {
        padding: 60px 20px;
      }
      @media(max-width: 1090px) {
        width: 65%;
      }
      @media(max-width: 825px) {
        width: 100%;
        padding: 20px;
      }

      .about-accordion {
        width: 65%;

        @media (max-width: 1550px) {
          width: 100%;
        }

        &__btn {
          background: transparent;
          border: none;
          font-size: 14px;
          color: $base-color;
          text-decoration: underline;
          margin-bottom: 16px;
          @media(min-width: 826px) {
            display: none;
          }
        }

        &__content {
          @media(min-width: 826px) {
            display: block !important;
          }
          @media(max-width: 825px) {
            display: none;
          }
        }
      }

      .title {
        color: #292929;
        font: {
          family: $ossem;
          size: rem(44px);
          weight: 600;
        }
        line-height: normal;
        margin-bottom: 23px;
        @media (max-width: 1550px) {
          margin-bottom: 30px;
        }
        @media(max-width: 825px) {
          margin-bottom: 16px;
        }
        @media(max-width: 768px) {
          margin-bottom: 8px;
        }

      }

      .text {
        color: #333a40;
        font: {
          family: $baseFonts;
          size: rem(14px);
          weight: $font-weight--regular;
        }
        line-height: 20.99px;

        p {
          margin-bottom: 30px;
          @media (max-width: 1550px) {
            margin-bottom: 15px;
          }
        }
      }

      .social-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          //width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: auto;
          @media (max-width: 525px) {
            display: block;
            a {
              display: inline-block;
              width: 30px;
            }
          }

          a {
            &:last-child {
              .icon {
                margin: 0;
              }
            }
          }

          .icon {
            margin-right: 20px;
            @include transition();


            @media (max-width: 525px) {
              margin-right: 10px;
            }
          }

          & > span {
            margin-left: 44px;
            color: #292929;
            font: {
              family: $baseFonts;
              size: rem(14px);
              weight: $font-weight--regular;
            }
            line-height: 20.99px;
            text-transform: uppercase;
            position: relative;

            &:before {
              position: absolute;
              content: '';
              width: 25px;
              height: 2px;
              background-color: #445364;
              top: 50%;
              margin-top: -1px;
              left: -40px;
            }

            @media (max-width: 525px) {
              display: none;
            }
          }
        }

        &__link {
          font-size: rem(16);
          line-height: 1.5;
          font-weight: 600;
          color: #1C4A9F;
          text-transform: uppercase;
        }
      }
    }

    .about-media {
      width: 50%;
      position: relative;
      //height: 524px;
      background: {
        //image: url('../images/bg-about.png');
        position: center;
        repeat: no-repeat;
        size: cover;
      }
      @media(max-width: 1090px) {
        width: 35%;
      }
      @media(max-width: 825px) {
        width: 100%;
      }
      @media(max-width: 580px) {
        height: 360px;
      }
      @media(max-width: 425px) {
        height: 280px;
      }

      .play {
        position: absolute;
        z-index: 3;
        background-color: rgba(105, 115, 126, 0.3);
        width: 100%;
        height: 100%;

        &:before {
          position: absolute;
          content: '';
          background-image: url(../images/vector/play-icon.svg);
          width: 80px;
          height: 80px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;

      }
    }
  }

}

.gallery {
  margin-bottom: 56px;

  .header-block {
    margin-bottom: 32px;
  }

}

.gallery-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding: 0 140px 32px;
  @media (max-width: 1095px) {
    gap: 16px;
    padding: 0;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }
  @media (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);

  }

  .gallery__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: $white;
    overflow: hidden;
    cursor: pointer;
    @include transition();

    &:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
    }


    .gallery-item__desc {
      padding: 16px;
      @media(max-width: 768px) {
        padding: 12px;
      }

      h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media(max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .txt {
        display: none;
      }
    }
  }

  .gallery-item__inner {
    display: block;
    width: 100%;
    padding-bottom: calc(380 / 345 * 100%);
    height: 0;
    position: relative;
    @include transition();

    &:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
    }

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover;
    }
  }


}

.mfp-content {
}

.mfp-fade--gallery {
  .mfp-content {
    position: static;
    padding: 64px;
    @media (max-width: 768px) {
      padding: 24px;
    }
    @media (max-width: 560px) {
      padding: 8px;
    }
  }


  .mfp-title {
    padding: 0;
  }
}

.mfp-figure--gallery {
  background-color: $white;
  //padding: 42px 176px;
  border-radius: 4px;
  width: 100%;
  max-width: 736px;
  position: relative;
  /* @media (max-width: 1095px) {
     padding: 24px 100px;
   }
   @media (max-width: 768px) {
     padding: 8px 70px;
   }
   @media (max-width: 425px) {
     padding: 8px 56px;
   }*/

  .mfp-img {
    padding: 0;
    max-height: none !important;
    width: 100%;
    height: auto;
    border-radius: 4px 4px 0 0;
  }

  .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    @include transition();
    @media (max-width: 768px) {
      top: 250px;
      width: 32px !important;
      height: 32px !important;
    }
    @media (max-width: 560px) {
      top: 180px;
    }

    &:hover {
      background-image: url("../images/arrow-left-hover.svg") !important;
    }

  }

  .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: -64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform-origin: unset !important;
    }
    @media (max-width: 768px) {
      right: 16px !important;
    }
  }

  .mfp-arrow-left {
    left: -64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform: scale(1);
    }
    @media (max-width: 768px) {
      left: 16px !important;
    }
  }

  .gallery-item__desc {
    color: $base-color;
    padding: 16px 24px;
    @media (max-width: 768px) {
      padding: 16px 16px 8px;
    }


    h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 560px) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    @include transition();
  }
}

.mfp-video {
  margin: auto;

  video {
    height: auto;
    max-height: 600px;
    object-fit: contain;
    // min-height: 90vh;
    background-color: $white !important;
    border-radius: 4px;
    width: 100%;
    max-width: 736px;
    padding: 0 !important;
  }
}

.mfp-container {
  position: relative;

  .mfp-close {
    position: fixed !important;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;
    background-image: url("../images/close.svg");
    top: 36px;
    right: 64px;
    background-color: #FFFFFF;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer !important;
    @media (max-width: 768px) {
      top: 32px;
      right: 32px;
      width: 32px !important;
      height: 32px !important;
    }
  }

  .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    @include transition();
    @media (max-width: 768px) {
      top: 250px;
      width: 32px !important;
      height: 32px !important;
    }
    @media (max-width: 560px) {
      top: 180px;
    }

    &:hover {
      background-image: url("../images/arrow-left-hover.svg") !important;
    }

  }

  .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: 64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform-origin: unset !important;
    }
    @media (max-width: 768px) {
      right: 16px !important;
    }
  }

  .mfp-arrow-left {
    left: 64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform: scale(1);
    }
    @media (max-width: 768px) {
      left: 16px !important;
    }
  }

  .gallery-item__desc {
    color: $base-color;
    padding: 16px 24px;
    @media (max-width: 768px) {
      padding: 16px 16px 8px;
    }


    h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 560px) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    @include transition();
  }
}


.mfp-iframe-holder .mfp-content {
  max-width: 1088px;
  position: relative;
}

.mfp-iframe-scaler {
  height: auto;
  min-height: 90vh;
  background-color: $white !important;
  border-radius: 4px;
  width: 100%;
  max-width: 736px;
  padding: 0 !important;
  @media (max-width: 1095px) {
    padding: 24px 100px;
  }
  @media (max-width: 768px) {
    padding: 8px 70px;
  }
  @media (max-width: 425px) {
    padding: 8px 56px;
  }

  /*  .mfp-close {
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center;
      background-image: url("../images/close.svg");
      top: 32px;
      right: 32px;
      font-size: 0;
      @media (max-width: 768px) {
        top: 16px;
        right: 16px;
      }
    }*/

  .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    @include transition();
    @media (max-width: 768px) {
      top: 250px;
      width: 32px !important;
      height: 32px !important;
    }
    @media (max-width: 560px) {
      top: 180px;
    }

    &:hover {
      background-image: url("../images/arrow-left-hover.svg") !important;
    }

  }

  .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: -64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform-origin: unset !important;
    }
    @media (max-width: 768px) {
      right: 16px !important;
    }
  }

  .mfp-arrow-left {
    left: -64px !important;
    margin: 0 !important;
    @media (max-width: 900px) {
      transform: scale(1);
    }
    @media (max-width: 768px) {
      left: 16px !important;
    }
  }

  .gallery-item__desc {
    color: $base-color;
    padding: 16px 24px;
    @media (max-width: 768px) {
      padding: 16px 16px 8px;
    }


    h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px;
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 20px;
      }
      @media (max-width: 560px) {
        font-size: 13px;
        line-height: 16px;
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      @media (max-width: 768px) {
        font-size: 13px;
        line-height: 18px;
      }
    }
  }

  .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    @include transition();
  }
}

.mfp-iframe {
  position: static !important;
  max-height: 80% !important;
  background: $white !important;
  box-shadow: none !important;
  margin-bottom: 24px;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 500px;
  border-radius: 4px 4px 0 0;
}


.show-more-card {
  display: none;
  padding-top: 10px;
  padding-left: 5px;
  font: {
    size: rem(18px);
    weight: $font-weight--semibold;
  }
  color: $link-color;
  @include transition();
  border: none;
  background-color: transparent;
  margin: 0 auto 24px auto;

  &.active {
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: $link-color--hover;
  }
}

.show-smaller-card {
  padding-top: 10px;
  padding-left: 5px;
  font: {
    size: rem(18px);
    weight: $font-weight--semibold;
  }
  color: lighten($green, 10%);
  @include transition();
  border: none;
  background-color: transparent;
  margin: 0 auto 24px auto;
  display: none;

  &.active {
    display: block;
  }

  &:hover {
    text-decoration: none;
    color: $yellow;
  }

  @media (max-width: 630px) {
    background-image: url(../images/slider-arrow-next.png);
    width: 50px;
    height: 50px;
    background-size: contain;
    right: 0;
    z-index: 999;
    display: none;
    position: absolute;
    overflow: hidden;
    border: none;
    background-color: rgba(255, 255, 255, 0.6);
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 0;
  }
}
.instagram {
  width: 100%;
  max-width: 1500px;
  margin:0 auto 60px;
   @media (max-width: 920px){
     margin-bottom: 40px;
   }
  .header-block {
      margin-bottom: 32px;
    &:after {
        display: none;
      }
    @media (max-width: 891px) {
      margin-bottom: 17px;
      &:after {
        display: none;
      }
    }
  }

  .wrapper {
    width: 100%;
  }

  &-list {
    margin-right: -12px;
    margin-left: -12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
     @media (max-width: 660px){
       margin-right: -8px;
       margin-left: -8px;
     }
  }

  &-item{
    display: block;
    margin:0 12px 24px;
    width: calc(25% - 24px);
    background-color: #ffffff;
     @media (max-width: 920px){
       width: calc((100% / 3) - 24px);
     }
    @media (max-width: 660px){
      width: calc(50% - 16px);
      margin: 0 8px 16px;
    }
    &__inner{
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center;
    }
  }
}
@charset "UTF-8";
/*------------------------------------*\
  # Path
\*------------------------------------*/
/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
/*------------------------------------*\
  # Print
\*------------------------------------*/
/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
/* Slider */
@import url(../../../node_modules/swiper/dist/css/swiper.min.css);
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.main-title {
  width: 100%;
  background-color: #fff;
  height: 150px;
  text-align: center;
  display: block;
  color: #505050;
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  font-weight: 600;
  line-height: 9.375rem;
  position: relative; }
  @media (max-width: 1410px) {
    .main-title {
      min-height: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px 0;
      height: auto;
      font-size: 2rem;
      line-height: 2.125rem; } }
  .main-title.catalog {
    background: transparent;
    text-align: left;
    height: auto;
    line-height: 40px;
    margin-top: 1.125rem; }
    @media (max-width: 1140px) {
      .main-title.catalog {
        height: auto;
        padding: 8px 0 0; } }
    @media (max-width: 768px) {
      .main-title.catalog {
        margin: 8px 0 0;
        padding: 0; } }
    .main-title.catalog .breadcrumbs {
      display: none; }
    .main-title.catalog .main-title-img {
      display: none; }
    .main-title.catalog h1 {
      background: transparent;
      text-align: center;
      font-size: 1.5rem;
      line-height: 150%;
      color: #404040; }
      @media (max-width: 550px) {
        .main-title.catalog h1 {
          line-height: 120%; } }
  .main-title h1 {
    position: relative;
    z-index: 2;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0.3) 100%); }
  @media (max-width: 560px) {
    .main-title {
      font-size: 1.625rem; } }
  .main-title-img {
    position: absolute;
    display: none;
    width: 50%;
    top: 0;
    height: 150px;
    overflow: hidden; }
    @media (max-width: 1410px) {
      .main-title-img {
        display: none; } }
    .main-title-img.left {
      left: 0; }
    .main-title-img.right {
      right: 0; }
    .main-title-img img {
      object-fit: cover;
      width: 100%; }
  .main-title-text {
    display: block;
    margin: 16px auto 0;
    max-width: 630px;
    font-size: 12px;
    line-height: 150%;
    color: #676463;
    text-align: center;
    font-weight: 400; }
    @media (max-width: 768px) {
      .main-title-text {
        margin: 8px 0 0; } }
  .main-title .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    line-height: 1.3;
    left: 50%;
    transform: translateX(-50%);
    flex-wrap: wrap; }
    @media (max-width: 1410px) {
      .main-title .breadcrumbs {
        position: static;
        transform: none;
        padding-top: 10px; } }
    .main-title .breadcrumbs li {
      line-height: 1.3;
      margin-right: 30px;
      position: relative;
      margin-bottom: 10px; }
      .main-title .breadcrumbs li:after {
        position: absolute;
        content: '';
        right: -19px;
        width: 10px;
        top: 50%;
        height: 2px;
        background-color: #edae00;
        display: block; }
      .main-title .breadcrumbs li:last-child {
        margin-right: 0; }
        .main-title .breadcrumbs li:last-child:after {
          display: none; }
      .main-title .breadcrumbs li a {
        display: block;
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
        color: #727272;
        line-height: 1.3;
        text-align: start; }

.constructor {
  max-width: 1920px;
  margin-top: -40px; }
  .constructor .slider {
    position: relative;
    background: url("../images/bg-slider.jpg") no-repeat top center;
    background-size: cover;
    width: 100%;
    max-height: 760px; }
  .constructor .header-block {
    position: absolute;
    z-index: 1000;
    margin-top: 20px; }
    .constructor .header-block:after {
      bottom: -15px; }
  .constructor .header-discription {
    width: 100%;
    z-index: 1000;
    position: absolute;
    text-align: center;
    margin-top: 90px;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    color: #8b8b8b; }
  .constructor .bigger-slider {
    width: 100%;
    height: 90%;
    position: relative;
    z-index: 999; }
    .constructor .bigger-slider .swiper-wrapper .swiper-slide {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .constructor .bigger-slider .swiper-wrapper .swiper-slide img {
        max-width: 100%;
        margin: auto;
        height: auto; }
      .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark {
        position: absolute;
        display: flex;
        align-items: flex-start;
        width: auto;
        cursor: pointer; }
        .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark .plus {
          background-image: url(../images/sprite.png);
          background-position: 0px -202px;
          width: 40px;
          height: 40px;
          margin-right: 15px; }
        .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark .name {
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 21px;
          color: #000;
          text-transform: uppercase; }
        .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark .price {
          line-height: 1.3125rem;
          font-weight: 400; }
          .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark .price-old {
            font-size: 0.875rem;
            color: #8b8b8b;
            text-decoration: line-through; }
          .constructor .bigger-slider .swiper-wrapper .swiper-slide .mark .price-normal {
            font-size: 1.3125rem;
            color: #477ab3; }
      .constructor .bigger-slider .swiper-wrapper .swiper-slide .swiper-slide-shadow-left {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)); }
      .constructor .bigger-slider .swiper-wrapper .swiper-slide .swiper-slide-shadow-right {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0)); }
  .constructor .calculator {
    background-color: #e8edec;
    padding: 55px 0 35px 140px;
    position: relative;
    z-index: 10000; }
    .constructor .calculator-inner {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
    .constructor .calculator-item {
      max-width: 400px;
      width: 100%;
      height: 165px;
      position: relative; }
      .constructor .calculator-item:not(.finish) {
        background-color: #fff;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between; }
        .constructor .calculator-item:not(.finish) .close {
          position: absolute;
          right: 10px;
          top: 10px;
          background-image: url(../images/sprite.png);
          background-position: -165px -148px;
          width: 27px;
          height: 27px; }
        .constructor .calculator-item:not(.finish) .img {
          width: 154px;
          height: 154px;
          display: flex;
          align-items: center;
          justify-content: center; }
          .constructor .calculator-item:not(.finish) .img img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            margin: auto;
            object-fit: cover; }
        .constructor .calculator-item:not(.finish) .info {
          padding: 0 20px 0 20px;
          box-sizing: border-box; }
          .constructor .calculator-item:not(.finish) .info .name {
            font-size: 0.875rem;
            font-weight: 600;
            font-family: "Montserrat", sans-serif;
            line-height: 1.3125rem;
            margin-bottom: 10px; }
          .constructor .calculator-item:not(.finish) .info .price {
            line-height: 1.3125rem;
            font-weight: 400; }
            .constructor .calculator-item:not(.finish) .info .price-old {
              font-size: 0.875rem;
              color: #8b8b8b;
              text-decoration: line-through; }
            .constructor .calculator-item:not(.finish) .info .price-normal {
              font-size: 1.3125rem;
              color: #477ab3; }
      .constructor .calculator-item.finish {
        padding: 20px;
        box-sizing: border-box;
        width: auto;
        max-width: 400px;
        min-width: 300px; }
        .constructor .calculator-item.finish .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px; }
          .constructor .calculator-item.finish .info > div {
            width: 50%; }
          .constructor .calculator-item.finish .info .price {
            line-height: 2rem;
            font-weight: 400; }
            .constructor .calculator-item.finish .info .price-old {
              font-size: 1.125rem;
              color: #8b8b8b;
              text-decoration: line-through; }
            .constructor .calculator-item.finish .info .price-normal {
              font-size: 1.75rem;
              color: #477ab3; }
          .constructor .calculator-item.finish .info .sale {
            width: auto;
            height: 50px;
            color: #ffcfae;
            font-size: 0.75rem;
            line-height: 1.3125rem;
            text-transform: uppercase;
            background-color: #fa7a24;
            padding: 5px 20px 5px 10px;
            border-radius: 5px;
            text-align: center;
            position: relative; }
            .constructor .calculator-item.finish .info .sale span {
              display: block;
              color: #fff;
              font-size: 1.5rem;
              font-weight: 600; }
            .constructor .calculator-item.finish .info .sale:before {
              position: absolute;
              content: '';
              border: 25px solid transparent;
              border-right: 27px solid #fa7a24;
              width: 50px;
              height: 50px;
              box-sizing: border-box;
              left: -49px;
              top: 0; }
        .constructor .calculator-item.finish .btn {
          display: block;
          width: auto;
          min-width: 100px;
          height: 50px;
          padding-left: 25px;
          padding-right: 25px;
          border-radius: 8px;
          background-color: #1C4A9F;
          text-align: center;
          transition: .2s; }
          .constructor .calculator-item.finish .btn span {
            line-height: 50px;
            color: #ffffff;
            font-family: "Montserrat", sans-serif;
            font-size: 0.875rem;
            font-weight: 600;
            text-transform: uppercase; }
          .constructor .calculator-item.finish .btn:hover {
            text-decoration: none;
            background-color: #245eca; }
            .constructor .calculator-item.finish .btn:hover span {
              text-decoration: none; }
    .constructor .calculator .plus, .constructor .calculator .summ {
      font-size: 40px;
      font-weight: 400;
      text-align: center;
      width: 40px;
      height: 40px;
      color: #747676; }

/*------------------------------------*\
  # Path
\*------------------------------------*/
/*------------------------------------*\
    # Colors
    # http://chir.ag/projects/name-that-color
\*------------------------------------*/
/*------------------------------------*\
    # Basic typography
\*------------------------------------*/
/*------------------------------------*\
    # Wrapper
\*------------------------------------*/
/*------------------------------------*\
  # Print
\*------------------------------------*/
/*------------------------------------*\
  # Magnific-Popup settings
\*------------------------------------*/
/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
/*------------------------------------------------------------------------*\
  # Hides an element visually while still allowing the content to be accessible,
    to assistive technology, e.g. screen readers. Passing `unhide` will reverse
    the affects of the hiding, which is handy for showing the element on focus,
    for example.
  # https://goo.gl/Vf1TGn
\*------------------------------------------------------------------------*/
/*------------------------------------*\
  # Transitions
\*------------------------------------*/
/*------------------------------------*\
  # Placeholder
\*------------------------------------*/
.search {
  position: relative;
  z-index: 20;
  margin-right: 35px; }
  @media (max-width: 1240px) {
    .search {
      position: static; } }
  @media (max-width: 600px) {
    .search {
      margin-right: 10px;
      padding-top: calc(50vh - 20px); } }
  @media (max-width: 375px) {
    .search {
      margin-right: 5px; } }
  .search.error .search-icon {
    color: #ff0000;
    background-image: url(../images/search-icon-error.svg); }
  .search-error {
    font-size: 12px;
    color: #a9a5a5;
    text-transform: uppercase;
    width: 100%;
    background-color: #fff; }
    @media (max-width: 1240px) {
      .search-error {
        right: 130px; } }
    @media (max-width: 600px) {
      .search-error {
        right: 15px;
        width: 230px;
        font-size: 9px; } }
  .search-icon {
    width: 90px;
    height: 24px;
    background-image: url(../images/search-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    border: none;
    outline: none;
    background-color: transparent; }
    @media (max-width: 1240px) {
      .search-icon {
        width: 24px;
        background-position: left 0 bottom -3px; } }
    .search-icon:hover .search-name {
      opacity: 0.8; }
    .search-icon.active {
      opacity: 0.8; }
    .search-icon span {
      padding-left: 30px;
      text-align: left;
      display: block;
      width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-size: 0.8125rem;
      text-transform: uppercase;
      font-weight: 600;
      cursor: pointer; }
      @media (max-width: 1240px) {
        .search-icon span {
          display: none; } }
  .search-wrapper {
    box-shadow: 4px 4px 8px rgba(56, 56, 56, 0.2);
    border-radius: 3px;
    position: absolute !important;
    top: 35px;
    left: -5px;
    display: none; }
    @media (max-width: 1240px) {
      .search-wrapper {
        right: 13px;
        left: auto; } }
    .search-wrapper::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      background-color: #fff;
      box-shadow: 0 0.5px 0.5px rgba(56, 56, 56, 0.2);
      transform: rotate(-135deg);
      position: absolute;
      top: -5px;
      left: 22px; }
      @media (max-width: 1240px) {
        .search-wrapper::after {
          left: auto;
          right: 30%; } }
      @media (max-width: 600px) {
        .search-wrapper::after {
          right: 80px; } }
  .search-input {
    color: #333a40;
    padding: 10px 20px;
    width: 430px;
    border: none;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px !important; }
    @media (max-width: 600px) {
      .search-input {
        width: calc(100vw - 26px); } }
  .search-btn {
    padding: 10px 5px;
    text-transform: uppercase;
    background-color: transparent;
    color: #1C4A9F;
    font-weight: 600;
    font-size: 13px;
    border: 0;
    position: absolute !important;
    top: 1px;
    right: 40px;
    border: none;
    outline: none;
    background-color: transparent; }
  .search-x {
    width: 24px;
    height: 24px;
    background-image: url(../images/x.svg);
    background-size: 100%;
    position: absolute;
    top: 7px;
    right: 10px;
    border: none;
    outline: none;
    background-color: transparent; }

.search-items {
  margin: 5px 0;
  position: absolute;
  width: 430px;
  background-color: #f8f8f8;
  top: 70px;
  left: -5px;
  padding: 0 10px 0 20px;
  overflow-y: auto; }
  @media (max-width: 1240px) {
    .search-items {
      right: 13px;
      left: auto; } }
  @media (max-width: 600px) {
    .search-items {
      top: 69px;
      width: calc(100vw - 26px);
      height: calc(50vh - 62px) !important; } }
  @media (max-width: 375px) {
    .search-items {
      width: 100%;
      padding: 0 5px;
      margin: 5px -15px; } }

.search_item {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 20px 0;
  text-decoration: none;
  padding: 5px 0 5px 5px;
  flex-wrap: nowrap; }
  .search_item:hover {
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
    text-decoration: none; }
  .search_item-img {
    width: 100px;
    margin-right: 10px;
    flex-shrink: 0; }
    @media (max-width: 425px) {
      .search_item-img {
        width: 80px; } }
    @media (max-width: 375px) {
      .search_item-img {
        width: 70px; } }
    .search_item-img img {
      width: 100%;
      display: block; }
  .search_item-title {
    color: #292929;
    font-size: 13px;
    font-weight: 600;
    margin-right: 10px;
    width: 400px; }
    @media (max-width: 560px) {
      .search_item-title {
        font-size: 12px;
        margin-right: 10px; } }
  .search_item-price {
    color: #292929;
    min-width: 80px;
    font-size: 14px; }
    @media (max-width: 560px) {
      .search_item-price {
        font-size: 13px;
        min-width: 75px; } }

.search_ims-empty {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 10px; }
  .search_ims-empty span {
    font-size: 16px;
    font-weight: 600;
    text-align: center; }

.showcase {
  margin-top: 40px;
  background-color: #f8f8f8;
  width: 100%;
  position: relative; }
  @media (max-width: 1095px) {
    .showcase {
      padding-top: 40px; } }
  @media (max-width: 500px) {
    .showcase {
      padding-top: 10px; } }
  .showcase .title {
    text-align: center;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-size: 1.3125rem;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 60px; }
    @media (max-width: 800px) {
      .showcase .title {
        margin-bottom: 15px; } }
  .showcase .header-block {
    margin-bottom: 32px; }
    .showcase .header-block:after {
      display: none; }
    @media (max-width: 891px) {
      .showcase .header-block:after {
        display: none; } }
  .showcase-btn {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end; }
    @media (max-width: 891px) {
      .showcase-btn {
        display: none; } }
    .showcase-btn .rainbow {
      position: absolute;
      width: auto;
      height: 40px;
      top: 32px;
      right: 0;
      opacity: 0;
      pointer-events: none;
      z-index: 1;
      box-sizing: border-box;
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
      background-color: rgba(255, 255, 255, 0.9);
      background-color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      border-radius: 8px;
      transition: 0.5s; }
      .showcase-btn .rainbow .select-color {
        display: block;
        width: 20px;
        height: 20px;
        margin: 10px;
        border-radius: 50%;
        position: relative;
        cursor: pointer;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover; }
        .showcase-btn .rainbow .select-color.active:after {
          border-color: #26937a; }
        .showcase-btn .rainbow .select-color .color-name {
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          background-color: #fff;
          padding: 2px 15px;
          box-sizing: border-box;
          width: auto;
          display: none;
          color: #26dabf;
          border: 2px solid #f4f0f0;
          border-radius: 10px;
          word-wrap: normal;
          word-break: normal;
          white-space: nowrap; }
        .showcase-btn .rainbow .select-color-text {
          display: block;
          width: 100%;
          position: absolute;
          z-index: -50;
          opacity: 0;
          text-indent: -99999px;
          overflow: hidden; }
        .showcase-btn .rainbow .select-color::after {
          position: absolute;
          content: '';
          left: 50%;
          top: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          width: 28px;
          height: 28px;
          border: 2px solid #f4f0f0;
          border-radius: 50%;
          transition: all 0.2s ease-in-out 0s; }
        .showcase-btn .rainbow .select-color:hover::after {
          border-color: #9ab7ed; }
        .showcase-btn .rainbow .select-color:hover .color-name {
          display: block; }
      .showcase-btn .rainbow.active {
        width: auto;
        height: 40px;
        right: 100%;
        opacity: 1;
        pointer-events: all; }
        .showcase-btn .rainbow.active .select-color {
          margin: 10px; }
    .showcase-btn .color-btn {
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 40px;
      padding-left: 20px;
      box-sizing: border-box;
      color: #1C4A9F;
      transition: all 0.2s ease-in-out 0s; }
      .showcase-btn .color-btn .icon {
        margin-right: 20px;
        display: none; }
      .showcase-btn .color-btn:hover {
        text-decoration: none;
        opacity: 0.8; }
  .showcase-list {
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    margin-bottom: 30px;
    flex-wrap: wrap;
    position: relative; }
    @media (max-width: 630px) {
      .showcase-list {
        display: block;
        margin-bottom: 20px; } }
    @media (min-width: 631px) {
      .showcase-list.five-item .swiper-container {
        overflow: visible;
        height: auto !important;
        width: 100% !important; } }
    @media (min-width: 631px) {
      .showcase-list.five-item .swiper-wrapper {
        transform: none;
        width: auto;
        height: auto;
        flex-wrap: wrap; } }
    .showcase-list.five-item .swiper-btn {
      display: none; }
      @media (max-width: 630px) {
        .showcase-list.five-item .swiper-btn {
          display: block;
          position: absolute;
          z-index: 2;
          overflow: hidden;
          text-indent: -99999px;
          border: none;
          background-color: rgba(255, 255, 255, 0.6);
          z-index: 99;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }
          .showcase-list.five-item .swiper-btn--prev {
            background-image: url(../images/slider-arrow-prev.png);
            width: 50px;
            height: 50px;
            background-size: contain;
            left: 0; }
          .showcase-list.five-item .swiper-btn--next {
            background-image: url(../images/slider-arrow-next.png);
            width: 50px;
            height: 50px;
            background-size: contain;
            right: 0; } }
    .showcase-list.five-item .showcase-item {
      width: calc(100% / 5 - 16px); }
      .showcase-list.five-item .showcase-item:nth-child(4n+4) {
        margin-right: 20px; }
      .showcase-list.five-item .showcase-item:nth-child(5n+5) {
        margin-right: 0; }
      @media (max-width: 1540px) {
        .showcase-list.five-item .showcase-item {
          width: calc(100% / 4 - 16px); }
          .showcase-list.five-item .showcase-item:nth-child(4n) {
            margin-right: 0; }
          .showcase-list.five-item .showcase-item:nth-child(5n) {
            margin-right: 20px; } }
      @media (max-width: 1240px) {
        .showcase-list.five-item .showcase-item {
          width: calc(100% / 3 - 16px); }
          .showcase-list.five-item .showcase-item:nth-child(4n) {
            margin-right: 20px; } }
      @media (max-width: 960px) {
        .showcase-list.five-item .showcase-item {
          width: calc(100% / 2 - 16px); } }
      @media (max-width: 630px) {
        .showcase-list.five-item .showcase-item {
          width: 100%; } }
    .showcase-list .show-more-card {
      padding-top: 10px;
      padding-left: 5px;
      font-size: 1.125rem;
      font-weight: 600;
      color: #1C4A9F;
      transition: all 0.2s ease-in-out 0s;
      border: none;
      background-color: transparent;
      margin: 0 auto 16px auto;
      display: none; }
      .showcase-list .show-more-card.active {
        display: block; }
        @media (max-width: 630px) {
          .showcase-list .show-more-card.active {
            display: none !important; } }
      .showcase-list .show-more-card:hover {
        text-decoration: none;
        color: #2054b5; }
    .showcase-list .show-smaller-card {
      padding-top: 10px;
      padding-left: 5px;
      font-size: 1.125rem;
      font-weight: 600;
      color: #245eca;
      transition: all 0.2s ease-in-out 0s;
      border: none;
      background-color: transparent;
      margin: 0 auto 16px auto;
      display: none; }
      .showcase-list .show-smaller-card.active {
        display: block; }
      .showcase-list .show-smaller-card:hover {
        text-decoration: none;
        color: #1C4A9F; }
      @media (max-width: 630px) {
        .showcase-list .show-smaller-card {
          background-image: url(../images/slider-arrow-next.png);
          width: 50px;
          height: 50px;
          background-size: contain;
          right: 0;
          z-index: 999;
          display: none;
          position: absolute;
          overflow: hidden;
          border: none;
          background-color: rgba(255, 255, 255, 0.6);
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          font-size: 0; } }
  .showcase-item {
    width: calc(100% / 4 - 16px);
    height: auto;
    margin: 0 20px 20px 0;
    padding: 20px 0;
    box-sizing: border-box;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 1240px) {
      .showcase-item {
        width: calc(100% / 3 - 16px); }
        .showcase-item:nth-child(3n + 3) {
          margin-right: 0 !important; } }
    @media (max-width: 960px) {
      .showcase-item {
        width: calc(50% - 10px); }
        .showcase-item:nth-child(3n + 3) {
          margin-right: 20px !important; }
        .showcase-item:nth-child(2n + 2) {
          margin-right: 0 !important; } }
    @media (max-width: 630px) {
      .showcase-item {
        width: 90%;
        max-width: 420px;
        margin-right: auto !important;
        margin-left: auto;
        padding: 15px 0; }
        .showcase-item:nth-child(3n + 3) {
          margin-right: auto !important; }
        .showcase-item:nth-child(2n + 2) {
          margin-right: auto !important; } }
    .showcase-item:nth-child(4n + 4) {
      margin-right: 0; }
      @media (max-width: 1240px) {
        .showcase-item:nth-child(4n + 4) {
          margin-right: 20px; } }
    .showcase-item:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2);
      max-height: none;
      z-index: 15;
      border-radius: 8px 8px 0 0; }
      .showcase-item:hover .img img {
        transform: scale(1.1); }
      .showcase-item:hover .showcase-item-inner {
        position: relative;
        box-shadow: 0 8px 8px rgba(56, 56, 56, 0.2); }
        @media (max-width: 1240px) {
          .showcase-item:hover .showcase-item-inner {
            position: relative;
            box-shadow: none;
            left: auto;
            right: auto; } }
        .showcase-item:hover .showcase-item-inner .showcase-item-hide {
          position: absolute;
          left: 0;
          right: 0;
          top: 100%;
          height: auto;
          padding: 20px;
          box-shadow: 0 8px 8px rgba(56, 56, 56, 0.2);
          border-radius: 0 0 8px 8px; }
          @media (max-width: 1240px) {
            .showcase-item:hover .showcase-item-inner .showcase-item-hide {
              display: none;
              padding: 0; } }
    .showcase-item .title {
      color: #292929;
      padding: 0 20px;
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      line-height: 1.625rem;
      flex-grow: 1;
      margin-bottom: 0;
      text-align: left;
      display: flex;
      align-items: center; }
      .showcase-item .title:hover {
        text-decoration: none;
        color: #434343; }
    .showcase-item .flag-list {
      position: absolute;
      top: 105px;
      left: 40px;
      display: flex;
      z-index: 2;
      flex-direction: column; }
    .showcase-item .flag {
      position: relative;
      width: auto;
      margin-bottom: 10px;
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.3;
      letter-spacing: 0.008em;
      padding: 0 33px 0 20px; }
      @media (max-width: 1140px) {
        .showcase-item .flag {
          height: 30px;
          line-height: 30px;
          border-radius: 8px; }
          .showcase-item .flag:after {
            display: none; } }
      @media (max-width: 560px) {
        .showcase-item .flag {
          top: 80px;
          left: 20px;
          right: auto; } }
      .showcase-item .flag.hit:after {
        position: absolute;
        content: '';
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        background-color: #FFCD1D;
        border-radius: 50%; }
      .showcase-item .flag.new:after {
        position: absolute;
        content: '';
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        background-color: #1C4A9F;
        border-radius: 50%; }
      .showcase-item .flag.sale:after {
        position: absolute;
        content: '';
        width: 22px;
        height: 22px;
        box-sizing: border-box;
        top: 50%;
        transform: translateY(-50%);
        left: -20px;
        background-color: #FEC1CC;
        border-radius: 50%; }
    .showcase-item .img {
      max-width: 100%;
      position: relative;
      padding-bottom: 80%;
      height: auto;
      width: 100%;
      overflow: hidden;
      margin: 24px auto 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative; }
      @media (max-width: 1400px) {
        .showcase-item .img {
          margin: 20px auto; } }
      @media (max-width: 630px) {
        .showcase-item .img {
          margin: 15px auto; } }
      .showcase-item .img:hover .fast-view {
        opacity: 1;
        display: none; }
        @media (max-width: 1240px) {
          .showcase-item .img:hover .fast-view {
            display: none; } }
      .showcase-item .img img {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        object-position: center;
        transition: 0.4s; }
      .showcase-item .img .fast-view {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out 0s;
        opacity: 0;
        display: none; }
        .showcase-item .img .fast-view-btn {
          width: 220px;
          height: 50px;
          border: 2px solid #555555;
          text-align: center;
          transition: all 0.2s ease-in-out 0s; }
          .showcase-item .img .fast-view-btn span {
            color: #292929;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 3.125rem;
            text-transform: uppercase; }
          .showcase-item .img .fast-view-btn:hover {
            text-decoration: none;
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.5); }
    .showcase-item-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 39px;
      background-color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
      margin: auto 0 0; }
    .showcase-item .info {
      display: none;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap; }
      @media (max-width: 1024px) {
        .showcase-item .info {
          display: flex; } }
      .showcase-item .info p {
        width: 100%; }
      .showcase-item .info .options {
        color: #a2a2a2;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 30px;
        display: inline-block;
        margin-right: 5px; }
      .showcase-item .info .value {
        color: #333a40;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 30px; }
      .showcase-item .info-color {
        width: 100%; }
        .showcase-item .info-color .value {
          display: block;
          margin-top: 15px;
          margin-bottom: 0;
          padding-left: 10px; }
          .showcase-item .info-color .value .color-radio {
            position: relative;
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 9px; }
            .showcase-item .info-color .value .color-radio-value {
              width: 20px;
              height: 20px;
              margin-right: 5px;
              border-radius: 50%;
              display: inline-block;
              cursor: pointer;
              overflow: hidden;
              text-indent: -9999px; }
              .showcase-item .info-color .value .color-radio-value:after {
                position: absolute;
                content: '';
                width: 30px;
                height: 30px;
                border: 2px solid #f4f0f0;
                border-radius: 50%;
                top: -7px;
                left: -7px;
                transition: all 0.2s ease-in-out 0s; }
              .showcase-item .info-color .value .color-radio-value:hover::after {
                border-color: #9ab7ed; }
            .showcase-item .info-color .value .color-radio input {
              position: absolute;
              top: 8px;
              left: 8px;
              z-index: -1; }
              .showcase-item .info-color .value .color-radio input:checked + span.color-radio-value:after {
                opacity: 1; }
    .showcase-item-hide {
      width: 100%;
      height: 0;
      overflow: hidden;
      background-color: #fff; }
      .showcase-item-hide .info {
        display: flex; }
        @media (max-width: 1024px) {
          .showcase-item-hide .info {
            display: none; } }
    .showcase-item-footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .showcase-item-footer .price {
        text-align: left;
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.3125rem; }
        .showcase-item-footer .price-old {
          color: #8b8b8b;
          text-decoration: line-through;
          height: 18px; }
        .showcase-item-footer .price-normal {
          color: #404040;
          font-size: 1.3125rem; }
      .showcase-item-footer .basket a {
        color: #1C4A9F;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 21px;
        text-transform: uppercase;
        display: flex;
        align-items: center; }
        .showcase-item-footer .basket a .icon {
          margin-right: 11px; }
        .showcase-item-footer .basket a:hover {
          text-decoration: none;
          color: #245eca; }

.instagram {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto 60px; }
  @media (max-width: 920px) {
    .instagram {
      margin-bottom: 40px; } }
  .instagram .header-block {
    margin-bottom: 32px; }
    .instagram .header-block:after {
      display: none; }
    @media (max-width: 891px) {
      .instagram .header-block {
        margin-bottom: 17px; }
        .instagram .header-block:after {
          display: none; } }
  .instagram .wrapper {
    width: 100%; }
  .instagram-list {
    margin-right: -12px;
    margin-left: -12px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap; }
    @media (max-width: 660px) {
      .instagram-list {
        margin-right: -8px;
        margin-left: -8px; } }
  .instagram-item {
    display: block;
    margin: 0 12px 24px;
    width: calc(25% - 24px);
    background-color: #ffffff; }
    @media (max-width: 920px) {
      .instagram-item {
        width: calc((100% / 3) - 24px); } }
    @media (max-width: 660px) {
      .instagram-item {
        width: calc(50% - 16px);
        margin: 0 8px 16px; } }
    .instagram-item__inner {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 100%; }
    .instagram-item img {
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      object-fit: cover;
      object-position: center; }

.main {
  width: 100%;
  height: auto; }
  .main .main-slider {
    height: calc(100vh - 50px);
    min-height: 500px;
    margin-bottom: 24px; }
    @media (max-width: 1095px) {
      .main .main-slider {
        height: calc(100vh - 122px);
        padding: 0; } }
    @media (max-width: 768px) {
      .main .main-slider {
        min-height: 450px;
        height: calc(100vh - 132px); } }
    @media (max-width: 425px) {
      .main .main-slider {
        min-height: 400px;
        height: calc(100vh - 112px); } }
    .main .main-slider .swiper-container {
      height: 100%; }
    .main .main-slider .swiper-slide {
      position: relative; }
      .main .main-slider .swiper-slide picture {
        display: block;
        width: 100%;
        height: 100%;
        position: relative; }
        .main .main-slider .swiper-slide picture img {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          object-fit: cover; }
      .main .main-slider .swiper-slide__bottom {
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1150px;
        max-width: 90%; }
        @media (max-width: 1095px) {
          .main .main-slider .swiper-slide__bottom {
            max-width: 80%; } }
        @media (max-width: 425px) {
          .main .main-slider .swiper-slide__bottom {
            max-width: calc(100% - 32px); } }
      .main .main-slider .swiper-slide__title {
        font-size: 3rem;
        line-height: 4rem;
        font-weight: 600;
        color: #ffffff;
        max-width: 70%;
        margin-bottom: 64px; }
        @media (max-width: 1095px) {
          .main .main-slider .swiper-slide__title {
            font-size: 2.5rem;
            line-height: 1.3; } }
        @media (max-width: 768px) {
          .main .main-slider .swiper-slide__title {
            font-size: 1.875rem;
            margin-bottom: 40px; } }
        @media (max-width: 425px) {
          .main .main-slider .swiper-slide__title {
            font-size: 1.75rem;
            max-width: 100%; } }
      .main .main-slider .swiper-slide__link {
        background: #FFFFFF;
        border-radius: 12px;
        padding: 12px 24px;
        font-weight: 600;
        cursor: pointer;
        color: #39B79C; }
        .main .main-slider .swiper-slide__link:hover {
          text-decoration: none; }
    .main .main-slider .pagination-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      z-index: 10; }
      .main .main-slider .pagination-wrapper .swiper-pagination-fraction {
        min-width: 130px;
        position: static;
        color: #ffffff;
        padding: 32px;
        width: max-content;
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 600; }
        @media (max-width: 768px) {
          .main .main-slider .pagination-wrapper .swiper-pagination-fraction {
            font-size: 1.0625rem; } }
        @media (max-width: 425px) {
          .main .main-slider .pagination-wrapper .swiper-pagination-fraction {
            font-size: 0.9375rem;
            padding: 24px 32px; } }
    .main .main-slider .swiper-button {
      width: 36px;
      height: 36px;
      border: none;
      position: absolute;
      z-index: 2;
      top: 50%;
      background-image: url("../images/slider-arrow.png");
      -webkit-background-size: contain;
      background-size: 20px 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: white;
      border-radius: 50%;
      transition: all 0.2s ease-in-out 0s; }
      .main .main-slider .swiper-button:hover {
        background-image: url("../images/slider-arrow-hover.png"); }
      @media (max-width: 768px) {
        .main .main-slider .swiper-button {
          display: none; } }
      .main .main-slider .swiper-button-prev {
        left: 16px;
        transform: translateY(-50%); }
      .main .main-slider .swiper-button-next {
        right: 16px;
        transform: translateY(-50%) rotate(180deg) !important; }
  .main-banner {
    width: 100%;
    margin-bottom: 80px; }
    @media (max-width: 1095px) {
      .main-banner {
        margin-bottom: 60px; } }
    @media (max-width: 768px) {
      .main-banner {
        margin-bottom: 20px; } }
    @media (max-width: 560px) {
      .main-banner {
        margin-bottom: 12px; } }
    .main-banner--wrapper {
      padding: 0 15px;
      max-width: 1920px; }
      @media (max-width: 560px) {
        .main-banner--wrapper {
          padding: 0; } }
    .main-banner .swiper-slide .bg {
      display: none;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      @media (max-width: 560px) {
        .main-banner .swiper-slide .bg {
          display: block; } }
    .main-banner .swiper-slide a {
      display: block;
      width: 100%; }
    .main-banner .main-banner__controls {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      z-index: 2;
      left: 11.1%;
      bottom: 13.92%; }
      @media (max-width: 560px) {
        .main-banner .main-banner__controls {
          display: none; } }
      .main-banner .main-banner__controls .main-banner__btn {
        position: relative;
        transition: all 0.2s ease-in-out 0s;
        width: 26px;
        min-width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.4); }
        .main-banner .main-banner__controls .main-banner__btn:hover {
          border: 1px solid white; }
        .main-banner .main-banner__controls .main-banner__btn--prev {
          margin-right: 12px; }
          .main-banner .main-banner__controls .main-banner__btn--prev::after {
            content: '';
            width: 6px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url(../images/vector/main-banner-arrow.svg);
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(-50%, -50%); }
        .main-banner .main-banner__controls .main-banner__btn--next {
          margin-left: 12px; }
          .main-banner .main-banner__controls .main-banner__btn--next::after {
            content: '';
            width: 6px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            background-image: url(../images/vector/main-banner-arrow.svg);
            -webkit-background-size: contain;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
            transform: translate(-50%, -50%) rotate(180deg); }
      .main-banner .main-banner__controls .main-banner__pagination .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ffffff;
        opacity: .5;
        margin: 0 3px; }
        .main-banner .main-banner__controls .main-banner__pagination .swiper-pagination-bullet-active {
          opacity: 1; }
    .off-controls .main-banner ~ .main-banner__controls {
      display: none; }
    .main-banner img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      font-family: 'object-fit:cover;';
      min-height: 500px;
      max-height: calc(100vh - 50px); }
      @media (max-width: 560px) {
        .main-banner img {
          height: auto;
          max-height: unset; } }
    .main-banner-text {
      position: absolute;
      z-index: 3;
      top: 40.55%;
      left: 11.1%;
      display: flex;
      flex-direction: column;
      max-width: 401px; }
      @media (max-width: 1200px) {
        .main-banner-text {
          max-width: 280px; } }
      @media (max-width: 1024px) {
        .main-banner-text {
          max-width: 230px;
          top: 13.92%; } }
      @media (max-width: 480px) {
        .main-banner-text {
          left: 5%;
          max-width: 90%; } }
      .main-banner-text .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 140%;
        color: #ffffff;
        word-break: break-word; }
        @media (max-width: 1200px) {
          .main-banner-text .title {
            font-size: 22px; } }
        @media (max-width: 768px) {
          .main-banner-text .title {
            font-size: 18px; } }
        @media (max-width: 560px) {
          .main-banner-text .title {
            font-weight: 600;
            font-size: 14px;
            text-align: left; } }
        @media (max-width: 480px) {
          .main-banner-text .title {
            display: none; } }
        .main-banner-text .title-mobile {
          display: none; }
          @media (max-width: 480px) {
            .main-banner-text .title-mobile {
              display: block;
              color: #ffffff;
              font-weight: 600;
              font-size: 14px;
              text-align: left;
              word-break: break-all; } }
      .main-banner-text .link {
        position: relative;
        align-self: flex-start;
        margin-top: 16px;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #ffffff; }
        @media (max-width: 768px) {
          .main-banner-text .link {
            margin-top: 10px; } }
        @media (max-width: 560px) {
          .main-banner-text .link {
            display: none; } }
        .main-banner-text .link:after {
          content: '';
          position: absolute;
          top: calc(100% + 8px);
          left: 0;
          display: block;
          width: 100%;
          height: 2px;
          background-color: #ffffff; }
          @media (max-width: 560px) {
            .main-banner-text .link:after {
              top: calc(100% + 3px); } }
  .main-video {
    width: 100%;
    margin-bottom: 80px; }
    @media (max-width: 1095px) {
      .main-video {
        margin-bottom: 60px; } }
    @media (max-width: 768px) {
      .main-video {
        margin-bottom: 20px; } }
    @media (max-width: 560px) {
      .main-video {
        margin-bottom: 12px; } }
    .main-video__video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      min-height: 500px;
      max-height: calc(100vh - 50px); }
      @media (max-width: 560px) {
        .main-video__video {
          height: calc(100vh - 112px); } }
  .main-categories {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    margin: 0 -16px 80px; }
    @media (max-width: 1095px) {
      .main-categories {
        margin-bottom: 60px; } }
    @media (max-width: 768px) {
      .main-categories {
        margin-bottom: 20px; } }
    @media (max-width: 560px) {
      .main-categories {
        margin-bottom: 12px; } }
    .main-categories__item {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      margin: 0 6px 12px;
      display: block;
      overflow: hidden;
      background-color: #fff; }
      @media (min-width: 1120px) {
        .main-categories__item:nth-child(3n + 1), .main-categories__item:nth-child(3n), .main-categories__item:nth-child(4n), .main-categories__item:nth-child(5n) {
          position: relative;
          display: flex;
          max-width: calc(40% - 32px); }
          .main-categories__item:nth-child(3n + 1) .inner, .main-categories__item:nth-child(3n) .inner, .main-categories__item:nth-child(4n) .inner, .main-categories__item:nth-child(5n) .inner {
            padding-bottom: 76%;
            position: relative;
            width: 100%; }
        .main-categories__item:nth-child(6n - 4), .main-categories__item:nth-child(6n) {
          position: relative;
          display: flex;
          max-width: calc(20% + 28px); }
          .main-categories__item:nth-child(6n - 4) .inner, .main-categories__item:nth-child(6n) .inner {
            padding-bottom: 64%;
            position: relative;
            width: 100%; } }
      @media (max-width: 1119px) {
        .main-categories__item {
          position: relative;
          display: flex;
          max-width: calc(40% - 12px); }
          .main-categories__item .inner {
            padding-bottom: 64%;
            position: relative;
            width: 100%; }
          .main-categories__item:nth-child(4n + 1), .main-categories__item:nth-child(4n) {
            position: relative;
            display: flex;
            max-width: calc(60% - 12px); }
            .main-categories__item:nth-child(4n + 1) .inner, .main-categories__item:nth-child(4n) .inner {
              padding-bottom: 76%;
              position: relative;
              width: 100%; } }
      .main-categories__item:hover {
        box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2); }
        .main-categories__item:hover .title {
          opacity: 0; }
          @media (max-width: 1200px) {
            .main-categories__item:hover .title {
              opacity: 1; } }
        .main-categories__item:hover .hover-block {
          background-color: rgba(0, 0, 0, 0.4); }
          .main-categories__item:hover .hover-block .hover-block__discription {
            opacity: 1;
            top: calc(50% + 30px); }
            @media (max-width: 1124px) {
              .main-categories__item:hover .hover-block .hover-block__discription {
                display: none; } }
      .main-categories__item img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 100%;
        object-fit: cover;
        height: 100%; }
      .main-categories__item .hover-block {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 1;
        transition: 0.2s; }
        .main-categories__item .hover-block__content {
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
          bottom: 0;
          padding: 0 20px;
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start; }
          @media (max-width: 1119px) {
            .main-categories__item .hover-block__content {
              padding: 0 5px; } }
        .main-categories__item .hover-block__title {
          position: relative;
          width: auto;
          display: block;
          color: #fff;
          font-family: "Montserrat", sans-serif;
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.3;
          text-align: center;
          margin: auto; }
          @media (max-width: 1119px) {
            .main-categories__item .hover-block__title {
              font-size: 0.875rem; } }
        .main-categories__item .hover-block__discription {
          position: absolute;
          bottom: -100%;
          display: block;
          color: #fff;
          font-family: "Montserrat", sans-serif;
          font-size: 0.875rem;
          font-weight: 400;
          line-height: 21px;
          max-width: 380px;
          text-align: center;
          margin: auto auto 20px;
          opacity: 0; }
          @media (max-width: 1124px) {
            .main-categories__item .hover-block__discription {
              display: none; } }

.popular {
  width: 100%;
  max-width: 1920px;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 80px;
  padding: 0 20px; }
  @media (max-width: 1140px) {
    .popular {
      flex-wrap: wrap;
      margin-bottom: 60px; } }
  @media (max-width: 991px) {
    .popular {
      margin-bottom: 10px; } }
  .popular-item {
    margin-right: 20px;
    flex-basis: 50%;
    width: calc(50% - 10px);
    max-width: 950px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; }
    @media (max-width: 1140px) {
      .popular-item {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 10px; } }
    .popular-item:last-child {
      margin-right: 0; }
    .popular-item__set {
      box-sizing: border-box;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding-bottom: 80%; }
      .popular-item__set:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2); }
      .popular-item__set .title {
        position: absolute;
        z-index: 9;
        top: 32px;
        left: 24px;
        width: auto;
        height: auto;
        color: #fff;
        font-family: "Montserrat", sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        line-height: 1.3;
        text-align: left;
        max-width: calc(100% - 24px - 200px - 16px); }
        @media (max-width: 1140px) {
          .popular-item__set .title {
            width: auto;
            line-height: 2.5rem; } }
        @media (max-width: 560px) {
          .popular-item__set .title {
            font-size: 14px;
            top: 10px;
            max-width: calc(100% - 48px); } }
      .popular-item__set .flag {
        width: auto;
        position: absolute;
        z-index: 10;
        right: 30px;
        top: 40px;
        color: #ffffff;
        font-family: "Montserrat", sans-serif;
        font-size: 1rem;
        font-weight: 600;
        line-height: 1.3;
        letter-spacing: 0.008em;
        padding: 0 33px 0 20px; }
        @media (max-width: 1140px) {
          .popular-item__set .flag {
            height: 30px;
            line-height: 30px;
            border-radius: 8px;
            top: 36px; } }
        @media (max-width: 560px) {
          .popular-item__set .flag {
            top: auto;
            bottom: 10px;
            left: 20px;
            right: auto; }
            .popular-item__set .flag:after {
              width: 10px !important;
              height: 10px !important;
              left: 0 !important; } }
        .popular-item__set .flag.hit:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #FFCD1D;
          border-radius: 50%; }
        .popular-item__set .flag.new:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #1C4A9F;
          border-radius: 50%; }
        .popular-item__set .flag.sale:after {
          position: absolute;
          content: '';
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          top: 50%;
          transform: translateY(-50%);
          left: -20px;
          background-color: #FEC1CC;
          border-radius: 50%; }
      .popular-item__set img {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        object-fit: cover;
        object-position: 50% 50%;
        width: 100%;
        height: 100%; }
        @media (max-width: 1140px) {
          .popular-item__set img {
            min-width: 100%;
            min-height: 100%; } }
    .popular-item__slider {
      width: 100%;
      height: 100%; }
      .popular-item__slider .popular-slider {
        height: 100%; }
        .popular-item__slider .popular-slider .swiper-button-next, .popular-item__slider .popular-slider .swiper-button-prev {
          outline: none; }
        .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide {
          width: 100%;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center; }
          @media (max-width: 1140px) {
            .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide {
              min-height: 250px; } }
          .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .title {
            position: absolute;
            left: 20px;
            top: 35px;
            max-width: none;
            width: auto;
            box-sizing: border-box;
            color: #fff;
            font-family: "Montserrat", sans-serif;
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1.3; }
            @media (max-width: 1140px) {
              .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .title {
                width: auto;
                padding: 0 20px 0 10px; } }
          .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide img {
            height: 100%;
            display: block;
            object-fit: cover;
            width: 100%; }
            @media (max-width: 1140px) {
              .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide img {
                min-width: 100%;
                min-height: 100%; } }
          .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag {
            width: auto;
            position: absolute;
            right: 30px;
            top: 40px;
            color: #ffffff;
            font-family: "Montserrat", sans-serif;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: 0.008em;
            padding: 0 33px 0 20px; }
            @media (max-width: 1140px) {
              .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag {
                height: 30px;
                line-height: 30px;
                border-radius: 8px; }
                .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag:after {
                  display: none; } }
            @media (max-width: 560px) {
              .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag {
                top: 80px;
                left: 20px;
                right: auto; } }
            .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag.hit:after {
              position: absolute;
              content: '';
              width: 22px;
              height: 22px;
              box-sizing: border-box;
              top: 50%;
              transform: translateY(-50%);
              left: -20px;
              background-color: #FFCD1D;
              border-radius: 50%; }
            .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag.new:after {
              position: absolute;
              content: '';
              width: 22px;
              height: 22px;
              box-sizing: border-box;
              top: 50%;
              transform: translateY(-50%);
              left: -20px;
              background-color: #1C4A9F;
              border-radius: 50%; }
            .popular-item__slider .popular-slider .swiper-wrapper .swiper-slide .flag.sale:after {
              position: absolute;
              content: '';
              width: 22px;
              height: 22px;
              box-sizing: border-box;
              top: 50%;
              transform: translateY(-50%);
              left: -20px;
              background-color: #FEC1CC;
              border-radius: 50%; }

.main-showcase {
  margin-bottom: 60px; }
  @media (max-width: 1095px) {
    .main-showcase {
      margin-top: 20px; } }
  @media (max-width: 1095px) {
    .main-showcase {
      margin-bottom: 40px; } }
  @media (max-width: 768px) {
    .main-showcase {
      margin-bottom: 0; } }
  @media (max-width: 630px) {
    .main-showcase .showcase-list {
      position: relative; } }
  .main-showcase .showcase-list__btn {
    width: 50px;
    height: 50px;
    border: none;
    position: absolute;
    z-index: 2;
    top: 50%;
    background-image: url(../images/slider-arrow-prev.png);
    -webkit-background-size: contain;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgba(255, 255, 255, 0.6); }
    @media (min-width: 631px) {
      .main-showcase .showcase-list__btn {
        display: none !important; } }
    .main-showcase .showcase-list__btn--prev {
      left: 0;
      transform: translateY(-50%); }
    .main-showcase .showcase-list__btn--next {
      right: 0;
      transform: translateY(-50%) rotate(180deg); }
  @media (min-width: 631px) {
    .main-showcase .showcase-list .swiper-container {
      overflow: visible; } }
  @media (max-width: 630px) {
    .main-showcase .showcase-list .swiper-container {
      width: calc(100% - 116px);
      max-width: 420px; } }
  @media (max-width: 425px) {
    .main-showcase .showcase-list .swiper-container {
      width: 100%; } }
  @media (min-width: 631px) {
    .main-showcase .showcase-list .swiper-wrapper {
      flex-wrap: wrap; } }
  @media (min-width: 631px) {
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-container {
      overflow: hidden; } }
  @media (min-width: 631px) {
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-wrapper {
      flex-wrap: nowrap; } }
  @media (max-width: 960px) {
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-slide {
      margin-right: 20px !important; } }
  @media (max-width: 630px) {
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-slide {
      margin-right: 20px !important; } }
  .main-showcase .showcase-list.showcase-slider-desktop .swiper-slide:hover {
    border-radius: 8px; }
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-slide:hover .showcase-item-hide {
      display: none;
      padding: 0; }
    .main-showcase .showcase-list.showcase-slider-desktop .swiper-slide:hover .showcase-item-inner {
      box-shadow: none; }
  @media (min-width: 631px) {
    .main-showcase .showcase-list.showcase-slider-desktop .showcase-list__btn {
      display: block !important; } }

.info-block {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  height: 100%;
  padding: 0 20px; }
  @media (max-width: 825px) {
    .info-block {
      flex-direction: column-reverse;
      height: auto;
      margin-bottom: 33px;
      max-height: unset; } }
  .info-block .img {
    width: 50%;
    display: block;
    position: relative; }
    @media (max-width: 1124px) {
      .info-block .img {
        height: auto; } }
    @media (max-width: 1090px) {
      .info-block .img {
        width: 35%; } }
    @media (max-width: 825px) {
      .info-block .img {
        width: 100%; } }
    .info-block .img img {
      display: block;
      width: 100%; }
  .info-block .info-content {
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    height: auto;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 50px 133px;
    text-align: center;
    color: #292929;
    font-family: "Montserrat", sans-serif;
    font-size: 0.875rem;
    font-weight: 400; }
    @media (max-width: 1366px) {
      .info-block .info-content {
        padding: 50px 24px; } }
    @media (max-width: 1090px) {
      .info-block .info-content {
        padding: 25px 4%;
        width: 65%; } }
    @media (max-width: 825px) {
      .info-block .info-content {
        width: 100%; } }
    @media (max-width: 768px) {
      .info-block .info-content {
        padding-top: 0; } }
    @media (max-width: 400px) {
      .info-block .info-content {
        padding: 25px 10px; } }
    .info-block .info-content .header-block {
      margin-bottom: 32px; }
      @media (max-width: 1090px) {
        .info-block .info-content .header-block {
          font-size: 1.5625rem;
          line-height: normal; }
          .info-block .info-content .header-block:after {
            bottom: -5px; } }
      @media (max-width: 768px) {
        .info-block .info-content .header-block {
          margin-bottom: 16px; } }
    .info-block .info-content-btn a {
      width: 260px;
      height: 50px;
      border-radius: 8px;
      background-color: #1C4A9F;
      transition: all 0.2s ease-in-out 0s;
      display: block;
      line-height: 50px;
      color: #ffffff;
      font-family: "Montserrat", sans-serif;
      font-size: 0.875rem;
      font-weight: 600;
      text-transform: uppercase; }
      .info-block .info-content-btn a:hover {
        background-color: #245eca;
        text-decoration: none; }

.about {
  width: 100%;
  overflow: hidden;
  margin-bottom: 80px; }
  @media (max-width: 1124px) {
    .about {
      height: auto; } }
  @media (max-width: 1095px) {
    .about {
      margin-bottom: 60px; } }
  @media (max-width: 768px) {
    .about {
      margin-bottom: 20px; } }
  @media (max-width: 560px) {
    .about {
      margin-bottom: 12px; } }
  .about-container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    background-color: #ffffff; }
    @media (max-width: 1550px) {
      .about-container {
        flex-wrap: wrap; } }
    @media (max-width: 825px) {
      .about-container {
        display: block; } }
    .about-container .about-content {
      width: 50%;
      padding: 60px;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: 110px -50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      @media (max-width: 1550px) {
        .about-container .about-content {
          padding: 60px 20px; } }
      @media (max-width: 1090px) {
        .about-container .about-content {
          width: 65%; } }
      @media (max-width: 825px) {
        .about-container .about-content {
          width: 100%;
          padding: 20px; } }
      .about-container .about-content .about-accordion {
        width: 65%; }
        @media (max-width: 1550px) {
          .about-container .about-content .about-accordion {
            width: 100%; } }
        .about-container .about-content .about-accordion__btn {
          background: transparent;
          border: none;
          font-size: 14px;
          color: #292929;
          text-decoration: underline;
          margin-bottom: 16px; }
          @media (min-width: 826px) {
            .about-container .about-content .about-accordion__btn {
              display: none; } }
        @media (min-width: 826px) {
          .about-container .about-content .about-accordion__content {
            display: block !important; } }
        @media (max-width: 825px) {
          .about-container .about-content .about-accordion__content {
            display: none; } }
      .about-container .about-content .title {
        color: #292929;
        font-family: "Montserrat", sans-serif;
        font-size: 2.75rem;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 23px; }
        @media (max-width: 1550px) {
          .about-container .about-content .title {
            margin-bottom: 30px; } }
        @media (max-width: 825px) {
          .about-container .about-content .title {
            margin-bottom: 16px; } }
        @media (max-width: 768px) {
          .about-container .about-content .title {
            margin-bottom: 8px; } }
      .about-container .about-content .text {
        color: #333a40;
        font-family: "Montserrat", sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 20.99px; }
        .about-container .about-content .text p {
          margin-bottom: 30px; }
          @media (max-width: 1550px) {
            .about-container .about-content .text p {
              margin-bottom: 15px; } }
      .about-container .about-content .social-links {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .about-container .about-content .social-links p {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: auto; }
          @media (max-width: 525px) {
            .about-container .about-content .social-links p {
              display: block; }
              .about-container .about-content .social-links p a {
                display: inline-block;
                width: 30px; } }
          .about-container .about-content .social-links p a:last-child .icon {
            margin: 0; }
          .about-container .about-content .social-links p .icon {
            margin-right: 20px;
            transition: all 0.2s ease-in-out 0s; }
            @media (max-width: 525px) {
              .about-container .about-content .social-links p .icon {
                margin-right: 10px; } }
          .about-container .about-content .social-links p > span {
            margin-left: 44px;
            color: #292929;
            font-family: "Montserrat", sans-serif;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 20.99px;
            text-transform: uppercase;
            position: relative; }
            .about-container .about-content .social-links p > span:before {
              position: absolute;
              content: '';
              width: 25px;
              height: 2px;
              background-color: #445364;
              top: 50%;
              margin-top: -1px;
              left: -40px; }
            @media (max-width: 525px) {
              .about-container .about-content .social-links p > span {
                display: none; } }
        .about-container .about-content .social-links__link {
          font-size: 1rem;
          line-height: 1.5;
          font-weight: 600;
          color: #1C4A9F;
          text-transform: uppercase; }
    .about-container .about-media {
      width: 50%;
      position: relative;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
      @media (max-width: 1090px) {
        .about-container .about-media {
          width: 35%; } }
      @media (max-width: 825px) {
        .about-container .about-media {
          width: 100%; } }
      @media (max-width: 580px) {
        .about-container .about-media {
          height: 360px; } }
      @media (max-width: 425px) {
        .about-container .about-media {
          height: 280px; } }
      .about-container .about-media .play {
        position: absolute;
        z-index: 3;
        background-color: rgba(105, 115, 126, 0.3);
        width: 100%;
        height: 100%; }
        .about-container .about-media .play:before {
          position: absolute;
          content: '';
          background-image: url(../images/vector/play-icon.svg);
          width: 80px;
          height: 80px;
          display: block;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); }
      .about-container .about-media iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        display: block; }

.gallery {
  margin-bottom: 56px; }
  .gallery .header-block {
    margin-bottom: 32px; }

.gallery-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 32px;
  padding: 0 140px 32px; }
  @media (max-width: 1095px) {
    .gallery-list {
      gap: 16px;
      padding: 0; } }
  @media (max-width: 768px) {
    .gallery-list {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px; } }
  @media (max-width: 425px) {
    .gallery-list {
      grid-template-columns: repeat(2, 1fr); } }
  .gallery-list .gallery__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #ffffff;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s ease-in-out 0s; }
    .gallery-list .gallery__container:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2); }
    .gallery-list .gallery__container .gallery-item__desc {
      padding: 16px; }
      @media (max-width: 768px) {
        .gallery-list .gallery__container .gallery-item__desc {
          padding: 12px; } }
      .gallery-list .gallery__container .gallery-item__desc h5 {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; }
        @media (max-width: 768px) {
          .gallery-list .gallery__container .gallery-item__desc h5 {
            font-size: 14px;
            line-height: 20px; } }
      .gallery-list .gallery__container .gallery-item__desc .txt {
        display: none; }
  .gallery-list .gallery-item__inner {
    display: block;
    width: 100%;
    padding-bottom: calc(380 / 345 * 100%);
    height: 0;
    position: relative;
    transition: all 0.2s ease-in-out 0s; }
    .gallery-list .gallery-item__inner:hover {
      box-shadow: 0 4px 8px rgba(56, 56, 56, 0.2); }
    .gallery-list .gallery-item__inner img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      object-fit: cover; }

.mfp-fade--gallery .mfp-content {
  position: static;
  padding: 64px; }
  @media (max-width: 768px) {
    .mfp-fade--gallery .mfp-content {
      padding: 24px; } }
  @media (max-width: 560px) {
    .mfp-fade--gallery .mfp-content {
      padding: 8px; } }

.mfp-fade--gallery .mfp-title {
  padding: 0; }

.mfp-figure--gallery {
  background-color: #ffffff;
  border-radius: 4px;
  width: 100%;
  max-width: 736px;
  position: relative;
  /* @media (max-width: 1095px) {
     padding: 24px 100px;
   }
   @media (max-width: 768px) {
     padding: 8px 70px;
   }
   @media (max-width: 425px) {
     padding: 8px 56px;
   }*/ }
  .mfp-figure--gallery .mfp-img {
    padding: 0;
    max-height: none !important;
    width: 100%;
    height: auto;
    border-radius: 4px 4px 0 0; }
  .mfp-figure--gallery .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 768px) {
      .mfp-figure--gallery .mfp-arrow {
        top: 250px;
        width: 32px !important;
        height: 32px !important; } }
    @media (max-width: 560px) {
      .mfp-figure--gallery .mfp-arrow {
        top: 180px; } }
    .mfp-figure--gallery .mfp-arrow:hover {
      background-image: url("../images/arrow-left-hover.svg") !important; }
  .mfp-figure--gallery .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: -64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-figure--gallery .mfp-arrow-right {
        transform-origin: unset !important; } }
    @media (max-width: 768px) {
      .mfp-figure--gallery .mfp-arrow-right {
        right: 16px !important; } }
  .mfp-figure--gallery .mfp-arrow-left {
    left: -64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-figure--gallery .mfp-arrow-left {
        transform: scale(1); } }
    @media (max-width: 768px) {
      .mfp-figure--gallery .mfp-arrow-left {
        left: 16px !important; } }
  .mfp-figure--gallery .gallery-item__desc {
    color: #292929;
    padding: 16px 24px; }
    @media (max-width: 768px) {
      .mfp-figure--gallery .gallery-item__desc {
        padding: 16px 16px 8px; } }
    .mfp-figure--gallery .gallery-item__desc h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px; }
      @media (max-width: 768px) {
        .mfp-figure--gallery .gallery-item__desc h5 {
          font-size: 16px;
          line-height: 20px; } }
      @media (max-width: 560px) {
        .mfp-figure--gallery .gallery-item__desc h5 {
          font-size: 13px;
          line-height: 16px; } }
    .mfp-figure--gallery .gallery-item__desc p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px; }
      @media (max-width: 768px) {
        .mfp-figure--gallery .gallery-item__desc p {
          font-size: 13px;
          line-height: 18px; } }
  .mfp-figure--gallery .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    transition: all 0.2s ease-in-out 0s; }

.mfp-video {
  margin: auto; }
  .mfp-video video {
    height: auto;
    max-height: 600px;
    object-fit: contain;
    background-color: #ffffff !important;
    border-radius: 4px;
    width: 100%;
    max-width: 736px;
    padding: 0 !important; }

.mfp-container {
  position: relative; }
  .mfp-container .mfp-close {
    position: fixed !important;
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    background-position: center;
    background-image: url("../images/close.svg");
    top: 36px;
    right: 64px;
    background-color: #FFFFFF;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer !important; }
    @media (max-width: 768px) {
      .mfp-container .mfp-close {
        top: 32px;
        right: 32px;
        width: 32px !important;
        height: 32px !important; } }
  .mfp-container .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 768px) {
      .mfp-container .mfp-arrow {
        top: 250px;
        width: 32px !important;
        height: 32px !important; } }
    @media (max-width: 560px) {
      .mfp-container .mfp-arrow {
        top: 180px; } }
    .mfp-container .mfp-arrow:hover {
      background-image: url("../images/arrow-left-hover.svg") !important; }
  .mfp-container .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: 64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-container .mfp-arrow-right {
        transform-origin: unset !important; } }
    @media (max-width: 768px) {
      .mfp-container .mfp-arrow-right {
        right: 16px !important; } }
  .mfp-container .mfp-arrow-left {
    left: 64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-container .mfp-arrow-left {
        transform: scale(1); } }
    @media (max-width: 768px) {
      .mfp-container .mfp-arrow-left {
        left: 16px !important; } }
  .mfp-container .gallery-item__desc {
    color: #292929;
    padding: 16px 24px; }
    @media (max-width: 768px) {
      .mfp-container .gallery-item__desc {
        padding: 16px 16px 8px; } }
    .mfp-container .gallery-item__desc h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px; }
      @media (max-width: 768px) {
        .mfp-container .gallery-item__desc h5 {
          font-size: 16px;
          line-height: 20px; } }
      @media (max-width: 560px) {
        .mfp-container .gallery-item__desc h5 {
          font-size: 13px;
          line-height: 16px; } }
    .mfp-container .gallery-item__desc p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px; }
      @media (max-width: 768px) {
        .mfp-container .gallery-item__desc p {
          font-size: 13px;
          line-height: 18px; } }
  .mfp-container .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    transition: all 0.2s ease-in-out 0s; }

.mfp-iframe-holder .mfp-content {
  max-width: 1088px;
  position: relative; }

.mfp-iframe-scaler {
  height: auto;
  min-height: 90vh;
  background-color: #ffffff !important;
  border-radius: 4px;
  width: 100%;
  max-width: 736px;
  padding: 0 !important;
  /*  .mfp-close {
      width: 36px;
      height: 36px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      background-position: center;
      background-image: url("../images/close.svg");
      top: 32px;
      right: 32px;
      font-size: 0;
      @media (max-width: 768px) {
        top: 16px;
        right: 16px;
      }
    }*/ }
  @media (max-width: 1095px) {
    .mfp-iframe-scaler {
      padding: 24px 100px; } }
  @media (max-width: 768px) {
    .mfp-iframe-scaler {
      padding: 8px 70px; } }
  @media (max-width: 425px) {
    .mfp-iframe-scaler {
      padding: 8px 56px; } }
  .mfp-iframe-scaler .mfp-arrow {
    background-image: url("../images/arrow-left.svg") !important;
    background-color: transparent !important;
    width: 40px !important;
    height: 40px !important;
    transition: all 0.2s ease-in-out 0s; }
    @media (max-width: 768px) {
      .mfp-iframe-scaler .mfp-arrow {
        top: 250px;
        width: 32px !important;
        height: 32px !important; } }
    @media (max-width: 560px) {
      .mfp-iframe-scaler .mfp-arrow {
        top: 180px; } }
    .mfp-iframe-scaler .mfp-arrow:hover {
      background-image: url("../images/arrow-left-hover.svg") !important; }
  .mfp-iframe-scaler .mfp-arrow-right {
    transform: rotate(180deg) !important;
    right: -64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-iframe-scaler .mfp-arrow-right {
        transform-origin: unset !important; } }
    @media (max-width: 768px) {
      .mfp-iframe-scaler .mfp-arrow-right {
        right: 16px !important; } }
  .mfp-iframe-scaler .mfp-arrow-left {
    left: -64px !important;
    margin: 0 !important; }
    @media (max-width: 900px) {
      .mfp-iframe-scaler .mfp-arrow-left {
        transform: scale(1); } }
    @media (max-width: 768px) {
      .mfp-iframe-scaler .mfp-arrow-left {
        left: 16px !important; } }
  .mfp-iframe-scaler .gallery-item__desc {
    color: #292929;
    padding: 16px 24px; }
    @media (max-width: 768px) {
      .mfp-iframe-scaler .gallery-item__desc {
        padding: 16px 16px 8px; } }
    .mfp-iframe-scaler .gallery-item__desc h5 {
      font-weight: 600;
      margin-bottom: 4px;
      font-size: 18px;
      line-height: 26px; }
      @media (max-width: 768px) {
        .mfp-iframe-scaler .gallery-item__desc h5 {
          font-size: 16px;
          line-height: 20px; } }
      @media (max-width: 560px) {
        .mfp-iframe-scaler .gallery-item__desc h5 {
          font-size: 13px;
          line-height: 16px; } }
    .mfp-iframe-scaler .gallery-item__desc p {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px; }
      @media (max-width: 768px) {
        .mfp-iframe-scaler .gallery-item__desc p {
          font-size: 13px;
          line-height: 18px; } }
  .mfp-iframe-scaler .gallery-item__link {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 8px auto 0 auto;
    display: block;
    width: max-content;
    transition: all 0.2s ease-in-out 0s; }

.mfp-iframe {
  position: static !important;
  max-height: 80% !important;
  background: #ffffff !important;
  box-shadow: none !important;
  margin-bottom: 24px;
  padding: 0;
  width: 100%;
  height: auto;
  min-height: 500px;
  border-radius: 4px 4px 0 0; }

.show-more-card {
  display: none;
  padding-top: 10px;
  padding-left: 5px;
  font-size: 1.125rem;
  font-weight: 600;
  color: #1C4A9F;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  background-color: transparent;
  margin: 0 auto 24px auto; }
  .show-more-card.active {
    display: block; }
  .show-more-card:hover {
    text-decoration: none;
    color: #2054b5; }

.show-smaller-card {
  padding-top: 10px;
  padding-left: 5px;
  font-size: 1.125rem;
  font-weight: 600;
  color: #245eca;
  transition: all 0.2s ease-in-out 0s;
  border: none;
  background-color: transparent;
  margin: 0 auto 24px auto;
  display: none; }
  .show-smaller-card.active {
    display: block; }
  .show-smaller-card:hover {
    text-decoration: none;
    color: #1C4A9F; }
  @media (max-width: 630px) {
    .show-smaller-card {
      background-image: url(../images/slider-arrow-next.png);
      width: 50px;
      height: 50px;
      background-size: contain;
      right: 0;
      z-index: 999;
      display: none;
      position: absolute;
      overflow: hidden;
      border: none;
      background-color: rgba(255, 255, 255, 0.6);
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      font-size: 0; } }
